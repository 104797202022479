
import { useGetScaleTypes, useUpdateScaleType, useAddScaleType } from "hooks/useScaleTypes";

export default function useScaleTypesPage () {

  const { isLoading: isFetchingScaleTypes, isSuccess: hasFetchedScaleTypes, data: scaleTypes = {}, refetch: refetchScaleTypes } = useGetScaleTypes();
  const { isLoading: isUpdatingScaleType, isSuccess: hasUpdatedScaleType, mutate: updateScaleType } = useUpdateScaleType();
  const { isLoading: isAddingScaleType, isSuccess: hasAddedScaleType, mutate: addScaleType } = useAddScaleType();

  return {
    isFetchingScaleTypes,
    hasFetchedScaleTypes,
    scaleTypes,
    refetchScaleTypes,
    isUpdatingScaleType,
    hasUpdatedScaleType,
    updateScaleType,
    isAddingScaleType,
    hasAddedScaleType,
    addScaleType,
  }

}