import { service } from '.';
import { AddSpeciesDetails, AddBreedDetails } from 'types';

export const v1 = '/api/v1';

export const getSpecies = () => service(`${v1}/species/all`, {method: 'GET'});
export const addSpecies = (params: AddSpeciesDetails) => service(`${v1}/species/add`, {method: 'POST', params});

export const getBreedsBySpecies = ({queryKey}: {queryKey: [string, string]}) => {

  const [_key, speciesId] = queryKey;

  const params = {speciesId};

  return service(`${v1}/races/get-by-species`, {method: 'GET', params})
  
};

export const addBreed = (params: AddBreedDetails) => service(`${v1}/races/add`, {method: 'POST', params});