import React from 'react';
import {  Modal, Input, Form, Select } from 'antd';
import useBreedModal from './useBreedModal';
import { getValuesForSelect } from 'utils';

interface AddBreedProps {
  speciesId: number,
  speciesName: string,
  isVisible: boolean,
  handleOk: any,
  handleCancel: any,
  confirmLoading: boolean,
}

const AddBreedModal = ({
  speciesId,
  speciesName,
  isVisible,
  handleOk,
  handleCancel,
  confirmLoading
}: AddBreedProps) => {

  const [form] = Form.useForm();
  const {
    isFetchingCountries,
    hasFetchedCountries,
    countries,
  } = useBreedModal();

  const onOk = async () => {

    try {
      
      const values = await form.validateFields();

      const valueWithSpecies = {
        ...values,
        speciesId,
      };

      handleOk(valueWithSpecies);

    } catch (errorInfo) {
      
      console.error('Failed:', errorInfo);
    
    }

  }

  return (
    <Modal
      title={`Add new breed to ${speciesName}`}
      visible={isVisible}
      onOk={onOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        name="new_breed"
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ 
            required: true, 
            message: 'Please input the species name',
          }]}
        >
          <Input 
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          rules={[{ 
            required: true, 
            message: 'Please input the species code',
          }]}
        >
          <Input 
            type="text"
          />
        </Form.Item>
        {/* <Form.Item
          name="countryId"
          label="Country"
          rules={[{ 
            required: true, 
            message: 'Please select country',
          }]}
        >
          <Select
            showSearch
            placeholder={'Search for country'}
            options={getValuesForSelect(countries, 'name', 'id')}
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );

}

export default AddBreedModal;