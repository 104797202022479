import { service } from 'api';
import { AddNewProductionPlaceParams, EditProductionPlaceParams } from 'types';

export const getAllProductionPlaces = () => service(`/api/v1/prod-places/admin-all`, {method: 'GET'});

export const getProductionPlace = ({queryKey}: {queryKey: [string, {productionPlaceId: number}]}) => {

  const [_key, productionPlaceId] = queryKey;

  return service(`/api/v1/prod-places/admin-get`, {method: 'GET', params: { id: productionPlaceId}});

}

export const addNewProductionPlace = (params: AddNewProductionPlaceParams) => service('/api/v1/prod-places/admin-add', {method: 'POST', data: params});

export const disableProductionPlace = (id: number) => service('/api/v1/prod-places/admin-disable', {method: 'POST', params: {id}});

export const reEnableProductionPlace = (id: number) => service('/api/v1/prod-places/admin-re-enable', {method: 'POST', params: {id}});

export const updateProductionPlace = (params: EditProductionPlaceParams) => service('/api/v1/prod-places/admin-edit', {method: 'PUT', data: params});
