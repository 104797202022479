
import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { AnimalWeightSpanParams } from 'types';


const { getWeightsInDateSpan, getTotalAnimalsByProductionPlace } = api;


export function useGetTotalAnimalsByProductionPlace(prodPlaceId: string | number) {

  return useQuery(
    ['get-total-animals-by-production-place', prodPlaceId],
    getTotalAnimalsByProductionPlace,
  );

}

export const useGetAnimalsWeights = () => useMutation((params: AnimalWeightSpanParams) => getWeightsInDateSpan(params));
