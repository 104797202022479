
import Highlighter from 'react-highlight-words';
import { map } from 'lodash';


export const colors = {
  success: '#32a852',
  red: 'red',
}

export const weightingTypes = [
  'someerrors',
  'manyerrors',
  'errorfree',
]

export const alarmOptions = [
  {
    label: '1 hour',
    value: 3600,
  },
  {
    label: '4 hours',
    value: 3600*4,
  },
  {
    label: '8 hours',
    value: 3600*8,
  },
  {
    label: '12 hours',
    value: 3600*12,
  },
  {
    label: '24 hours',
    value: 3600*24,
  },
  {
    label: '2 days',
    value: (3600*24)*2,
  },
  {
    label: '3 days',
    value: (3600*24)*3,
  },
  {
    label: '4 days',
    value: (3600*24)*4,
  },
  {
    label: '5 days',
    value: (3600*24)*5,
  },
  {
    label: '6 days',
    value: (3600*24)*6,
  },
];

export const highlightText = (text: string, searchText: string) => {

  return searchText.length ? (
    <Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069',
        padding: 0
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />
  ) : (
    text
  );

}

export const getValuesForSelect = (array: Array<object>, label: string, value: string ) => {

  return map(array, (item: any) => {
    return {
      label: item[label],
      value: item[value],
    }
  })

}



export const geocodeAddress = async (address: string) => {
  
  const apiKey = 'AIzaSyDJoe4MugAHLGivs4ITG11JQV1y8INLJCE';
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`; //`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`;
  const response = await fetch(url); //`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
  const data = await response.json();

  if (data.status === 'OK') {
      const location = data.results[0].geometry.location;
      return {
          latitude: location.lat,
          longitude: location.lng
      };
  } else {
      // throw new Error('Geocoding failed');
  }
};

