
import { List, Modal, Table, Button, message, Form, Select } from 'antd';
import { useEffect } from 'react'; 
import { Account } from 'types';
import { filter } from 'lodash';
import { UseMutateFunction } from 'react-query';
import { getValuesForSelect } from 'utils';

export default function EditUsersModal ({
  accounts,
  allAccounts,
  farmId,
  isVisible,
  onHide,
  hasFetchedAllAccounts,
  isRemovingFarmFromAccount,
  hasRemovedFarmFromAccount,
  removeFarmFromAccount,
  refetch,
  isAddingFarmToAccount,
  hasAddedFarmToAccount,
  addFarmToAccount,
}: {
  accounts: string[],
  allAccounts: Account[],
  farmId: number,
  isVisible: boolean,
  onHide: () => void,
  hasFetchedAllAccounts: boolean,
  isRemovingFarmFromAccount: boolean,
  hasRemovedFarmFromAccount: boolean,
  removeFarmFromAccount: UseMutateFunction<any, unknown, { accountId: number; farmId: number; }, unknown>,
  refetch: () => void,
  isAddingFarmToAccount: boolean,
  hasAddedFarmToAccount: boolean,
  addFarmToAccount: UseMutateFunction<any, unknown, { accountId: number; farmId: number; }, unknown>, 
}) {

  useEffect(() => {

    const key = "status";

    if (isRemovingFarmFromAccount) {

       message.loading({
         content: 'Removing user from farm',
         key,
       })

    }

    if (hasRemovedFarmFromAccount) {

      message.success({
        content: 'User has been removed',
        key,
      });

      refetch();

   }

  }, [
    isRemovingFarmFromAccount,
    hasRemovedFarmFromAccount,
  ])

  useEffect(() => {

    const key = "status";

    if (isAddingFarmToAccount) {

       message.loading({
         content: 'Giving user access to farm',
         key,
       })

    }

    if (hasAddedFarmToAccount) {

      message.success({
        content: 'Successfully gave user access to farm',
        key,
      });

      refetch();

   }

  }, [
    isAddingFarmToAccount,
    hasAddedFarmToAccount,
  ])

  const onSave = (result: any) => {

    addFarmToAccount({accountId: result.user, farmId: farmId});

  }

  const allUsers = filter(allAccounts, (account) => accounts.indexOf(account.email) > -1);
  
  return (
    <Modal
      title="Handle users"
      visible={isVisible}
      onOk={onSave}
      onCancel={onHide}
      // confirmLoading={}
    >
      <Form
        name="addUser"
        onFinish={onSave}
        layout="inline"
        style={{
          marginBottom: 10,
        }}
      >
        <Form.Item
          name="user"
          style={{
            marginBottom: 10,
          }}
        >
          <Select
            showSearch
            placeholder={'Select user'}
            options={getValuesForSelect(filter(allAccounts, (user) => accounts.indexOf(user.email) == -1), 'email', 'id')}
            style={{
              width: 200,
            }}
            filterOption={(input, option) => {
              //@ts-ignore
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              //@ts-ignore
              optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
            }
          />
        </Form.Item>
        <Button 
          type="primary"
          htmlType="submit"
        >
            Add user
          </Button>
      </Form>
      
      <Table 
        columns={[
          {
            title: 'Name',
            render: (text: string, record: any) => <>{record.email}</>,
            sorter:(a: any, b: any) => a.localeCompare(b)
          },
          {
            title: 'Actions',
            render : (text: string, record: any) => (
              <>
                <Button
                  danger
                  onClick={() => removeFarmFromAccount({accountId: record.id, farmId})}
                >
                  Remove
                </Button>
              </>
            )
          }
        ]}
        dataSource={allUsers}
      /> 
    </Modal>
  );

}