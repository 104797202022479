import { useMutation, useQuery } from 'react-query';
import api from 'api';
import { AccountItem } from 'types';

const { 
  getAccounts,
  addAccount,
  getAccount,
  disableAccount,
  reEnableAccount,
  editAccount,
  addFarmToAccount,
  removeFarmFromAccount,
  addGroupToAccount,
  removeGroupFromAccount,
} = api;


export const useGetAccount = (accountId: string | null) => {

  return useQuery(
    ['get-account', accountId],
    getAccount,
    {
      enabled: !!accountId,
      staleTime: Infinity,
    }
  );

}

export const useGetAccounts = () => useQuery('get-accounts', getAccounts);

export const useAddNewAccount = () => useMutation((params: AccountItem) => addAccount(params));

export const useDisableAccount = () => useMutation((params: {id: number}) => disableAccount(params));

export const useReEnableAccount = () => useMutation((params: {id: number}) => reEnableAccount(params));

export const useEditAccount = () => useMutation((params: AccountItem) => editAccount(params));

export const useAddFarmToAccount = () => useMutation((params: {accountId: number, farmId: number}) => addFarmToAccount(params));

export const useRemoveFarmFromAccount = () => useMutation((params: {accountId: number, farmId: number}) => removeFarmFromAccount(params));

export const useAddGroupToAccount = () => useMutation((params: {accountId: number, groupId: number}) => addGroupToAccount(params));

export const useRemoveGroupFromAccount = () => useMutation((params: {accountId: number, groupId: number}) => removeGroupFromAccount(params));

