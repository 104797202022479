
import { useState, useEffect } from 'react';
import useProductionPlace from './useProductionPlace';
import { colors } from 'utils';
import { Spin, Row, Col, Typography, Dropdown, Card, List, Menu, Tag, message, Statistic } from 'antd';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import SimpleForm from 'components/SimpleForm';
import { map } from 'lodash';
import HandleProductionPlaceForm from 'components/HandleProductionPlaceForm';
const { Title } = Typography;

export default function ProductionPlacePage() {

  const [isEditing, setIsEditing] = useState(false);

  const {
    isFetchingProductionPlace,
    productionPlace,
    hasFetchedProductionPlace,
    refetchProductionPlace,
    isDisablingProductionPlace,
    hasDisabledProductionPlace,
    disableProductionPlace,
    isReEnablingProductionPlace,
    hasReEnabledProductionPlace,
    reEnableProductionPlace,
    isFetchingAnimalsCount,
    hasFetchedAnimalsCount,
    animalsCount,
  } = useProductionPlace();

  useEffect(() => {

    const key = "status";

    if (isReEnablingProductionPlace) {

       message.loading({
         content: 'Enabling Production place',
         key,
       })

    }

    if (isDisablingProductionPlace) {

      message.loading({
        content: 'Disabling Production place',
        key,
      })

    }

    if (hasReEnabledProductionPlace || hasDisabledProductionPlace) {

      message.success({
        content: 'Done!',
        key,
      });

      refetchProductionPlace();
      
    }

  }, [
    isReEnablingProductionPlace,
    isDisablingProductionPlace,
    hasReEnabledProductionPlace,
    hasDisabledProductionPlace,
  ])

  const {code, name, farmCompanyName, farmName, farmId, id, isActive, productionOrientation, speciesName } = productionPlace;

  const actionsMenu = (
    <Menu>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            setIsEditing(true);

          }}
        >
          Edit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            isActive ? disableProductionPlace(id) : reEnableProductionPlace(id);

          }}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </a>
      </Menu.Item>
    </Menu>
  );

  if (isEditing) {

    return (
      <HandleProductionPlaceForm 
        initialValues={productionPlace}
        backAction={() => setIsEditing(false)}
        action={'update'}
        id={productionPlace.id}
        refetch={refetchProductionPlace}
      />
    );

  }

  return (
    <Spin spinning={isFetchingProductionPlace}>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Title level={2} style={{marginTop: 20,}}>
            {code} {name}
            <Dropdown
              overlay={actionsMenu}
              trigger={['click']}
            >
              <HiDotsHorizontal
                style={{
                  cursor: 'pointer',
                  marginTop: 1,
                  marginLeft: 10,
                  marginBottom: -3,
                  width: 25,
                  height: 25,
                }}
              />
            </Dropdown>
          </Title>
          
        </Col>
        <Col xs={24} md={12}>
          <Card title="General info" bordered={false}>
              <List
                bordered={false}
              >
                <List.Item>
                  <strong>Farm</strong> <br/>
                  <Link to={`/farms/${farmId}`}>{farmName}</Link> <br />
                  {farmCompanyName}
                </List.Item>
                
                <List.Item>
                  <strong>Farm ID (Hencols internal)</strong>  <br/>
                  {farmId}
                </List.Item>
                <List.Item>
                  <strong>Status</strong> <br />
                  <ActiveStatusBadge isActive={isActive} />
                </List.Item>
                <List.Item>
                  <strong>Species</strong>  <br/>
                  {speciesName}
                </List.Item>
              </List>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Animals" bordered={false}>
              <Statistic 
                value={animalsCount.recordCount}
                loading={isFetchingAnimalsCount}
                title={'Live animals'}
              />
          </Card>
        </Col>
      </Row>
    </Spin>
  );

}