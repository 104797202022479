
import { useState } from 'react';
import { useGetAllGroups, useGetAccountsByGroup, useAddNewGroup } from 'hooks/useGroups';

export default function useGroup() {

  const [groupIdForAccounts, setGroupIdForAccounts] = useState(null);

  const { isLoading: isFetchingGroups, isSuccess: hasFetchedGroups, data: groups = {}, refetch: refetchGroups } = useGetAllGroups();
  const { isLoading: isFetchingAccountsByGroups, isSuccess: hasFetchedAccountsByGroups, data: accountsByGroups = {}, refetch: refetchAccountsByGroups } = useGetAccountsByGroup(groupIdForAccounts);
  const { isLoading: isAddingNewGroup, isSuccess: hasAddedNewGroup, mutate: addNewGroup } = useAddNewGroup();

  return {
    isFetchingGroups,
    hasFetchedGroups,
    groups,
    refetchGroups,
    isFetchingAccountsByGroups,
    hasFetchedAccountsByGroups,
    accountsByGroups,
    refetchAccountsByGroups,
    groupIdForAccounts,
    setGroupIdForAccounts,
    isAddingNewGroup,
    hasAddedNewGroup,
    addNewGroup,
  };

}