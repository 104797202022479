type SelectValues = {
  label: string,
  value: number,
}

export default function (
  farms: Array<SelectValues>, 
  productionOrientation: Array<SelectValues>,
  species: Array<SelectValues>,
) { 
  
  return [
    {
      name: 'name',
      label: 'Name',
      type: 'input',
      props: {
        // required: true,
      },
    },
    {
      name: 'code',
      label: 'Code',
      type: 'input',
      props: {
        required: true,
      }
    },
    {
      name: 'farmId',
      label: 'Farm',
      type: 'select',
      props: {
        required: true,
      },
      options: farms,
    },
    {
      name: 'productionOrientationId',
      label: 'Production orientation',
      type: 'select',
      props: {
        required: true,
      },
      options: productionOrientation,
    },
    {
      name: 'speciesId',
      label: 'Species',
      type: 'select',
      props: {
        required: true,
      },
      options: species,
    },
    {
      name: "daysLimit",
      label: "",
      type: "input",
      value: 200,
      props: {
        hidden: true,
      }
    },
  ];

};