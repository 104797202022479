
import { service } from 'api';
import {
  UpdateScaleParams,
  AddScaleParams,
} from 'types';

export const getScale = ({queryKey}: {queryKey: [string, {scaleId: number}]}) => {

  const [_key, scaleId] = queryKey;
  
  return service(`/api/v1/scales/admin-get?id=${scaleId}`, {method: 'GET'});

}

export const getScales = () => service(`/api/v1/scales/admin-all`, {method: 'GET'});

export const updateScale = (params: UpdateScaleParams) => service(`/api/v1/scales/admin-edit`, {method: 'PUT', data: params});

export const disableScale = (id: number) => service(`/api/v1/scales/admin-disable`, {method: 'POST', params: {id}});

export const reEnableScale = (id: number) => service(`/api/v1/scales/admin-re-enable`, {method: 'POST', params: {id}});

export const addNewScale = (params: AddScaleParams) => service('/api/v1/scales/admin-add', {method: 'POST', data: params});

