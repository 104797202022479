import { Form, Input, Button, Layout, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from 'assets/images/logo.png';
import api from 'api';
import { useMutation } from "react-query";
import { LoginDetails } from 'types';
import useLoginPage from './useLogin';
import { useEffect } from 'react';

const { login } = api;

const Login = () => {
  
  const {
    hasLoggedIn,
    isLoggingIn,
    loginResults,
    loginFailed,
    login,
  } = useLoginPage();

  useEffect(() => {

    if (hasLoggedIn) {

      localStorage.setItem('hencol-admin-session', JSON.stringify(loginResults));
      window.location.reload();

    }

    if (loginFailed) {
      
      message.error('Sorry, login failed. The combination of email and password is invalid.');

    }
      
  },
  [
    hasLoggedIn,
    loginFailed,
  ])

  const onFinish = async (values: LoginDetails) => {

    const { username, password } = values;
    login({username, password});
    
  };

  const onFinishFailed = (errorInfo: any) => {
    
    console.log('Failed:', errorInfo);

  };



  return (
    <Layout
      
    >
      <Row
        justify="center"
        align="middle"
        style={{
          minHeight: '100vh',
        }}
      >
        <Col>
          <Row
            justify="center"
            align="middle"  
          >
            <Col>
              <img
                width={150}
                src={logo}
                alt="Hencol logo"
                style={{
                  paddingBottom: '40px',
                }}
              />
            </Col>
          </Row>
          <Form
            name="login"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size='large'
            style={{
              maxWidth: '100%',
              width: '300px',
            }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your Username!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={isLoggingIn}
                style={{
                  width: '100%',
                }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;