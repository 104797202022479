import useAnimalsPage from "./useAnimals";
import { Layout, Typography, Tabs } from 'antd';
import WeightsInDateSpan from "./components/WeightsInDateSpan";
import ForwardLink from "components/ForwardLink";
import { Link } from 'react-router-dom';

export default function AnimalsPage () {

  const {
    isFetchingWeights,
    hasFetchedWeights,
    weights,
    fetchWeights,
    isFetchingScales,
    hasFetchedScales,
    scales,
  } = useAnimalsPage();

  return (
    <Layout>  
      <Typography.Title>
        Animals
      </Typography.Title>
      <Link
        to="/species"
        style={{
          display: 'block',
          marginTop: -10,
          marginBottom: 10,
        }}
      >
        <ForwardLink text="Species & breeds" />
      </Link>
      <Tabs defaultActiveKey="weights">
        <Tabs.TabPane tab="Weights" tabKey="weights">
          <WeightsInDateSpan 
            isFetchingWeights={isFetchingWeights}
            hasFetchedWeights={hasFetchedWeights}
            weights={weights}
            fetchWeights={fetchWeights}
            isFetchingScales={isFetchingScales}
            hasFetchedScales={hasFetchedScales}
            scales={scales}
          />
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );

}