import { useGetCountries } from "hooks/useCountries";


export default function useBreedModal () {

  const { isLoading: isFetchingCountries, isSuccess: hasFetchedCountries, data: countries} = useGetCountries();

  return {
    isFetchingCountries,
    hasFetchedCountries,
    countries,
  }

}