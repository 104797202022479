

export default [
  {
    name: 'Foder effektivitet',
    priority: null,
    owner: {
      internal: "Robyn",
      external: null,
    },
    financier: "JBV",
    goal: "Make is possible for farmers to have insights info fodder costs and growth",
    purpose: null,
    currentMileStone: "Completion",
    deadline: "2021-12",
    status: "Closing",
  },
  {
    name: 'Rätt råd',
    priority: null,
    owner: {
      internal: "Robyn",
      external: null,
    },
    financier: "JBV",
    goal: "Create new features and solutions for farmers",
    purpose: null,
    currentMileStone: "Production follow up",
    deadline: "2023-02",
    status: "Started",
  },
  {
    name: 'J-O Brink',
    priority: null,
    owner: {
      internal: "Peter",
      external: "Jan-Ove Brink",
    },
    financier: null,
    goal: "Improve offering on Swedish market",
    purpose: null,
    currentMileStone: "Starting cooperation",
    deadline: null,
    status: "Started",
  },
  {
    name: 'Nordens Ark',
    priority: null,
    owner: {
      internal: "Henrik",
      external: "Mats Niklasson",
    },
    financier: "JBV",
    goal: "Handling new breeds + animal welfare",
    purpose: null,
    currentMileStone: null,
    deadline: "2023-12",
    status: "Started",
  },
  {
    name: 'Gotland',
    priority: null,
    owner: {
      internal: "Christer",
      external: null,
    },
    financier: null,
    goal: "Digitalized slaughter house",
    purpose: "Create new tech",
    currentMileStone: "Installation",
    deadline: null,
    status: "Started",
  },
  {
    name: 'Finland',
    priority: null,
    owner: {
      internal: "Fredrik",
      external: "Sami-jussi (HK Scan)",
    },
    financier: null,
    goal: "Start cooperation with Scan in Finland",
    purpose: null,
    currentMileStone: "Preparations before installation",
    deadline: null,
    status: "Started",
  },
  {
    name: 'Germany',
    priority: null,
    owner: {
      internal: "Christer",
      external: "Kristin @ Fodjan",
    },
    financier: null,
    goal: "Start cooperation with Fodjan in Germany",
    purpose: "Go international?",
    currentMileStone: "Sending equipment",
    deadline: null,
    status: "Started",
  },
  {
    name: 'IR Camera',
    priority: null,
    owner: {
      internal: "Christer",
      external: null,
    },
    financier: null,
    goal: "Develop infrared camera with BMP that can detect fever in cattle",
    purpose: null,
    currentMileStone: null,
    deadline: null,
    status: "Started",
  },
  {
    name: 'Volta Green Tech',
    priority: null,
    owner: {
      internal: "Christer?",
      external: null,
    },
    financier: null,
    goal: "Start cooperation with Volta Green Tech",
    purpose: null,
    currentMileStone: null,
    deadline: null,
    status: "Not started",
  },
  {
    name: 'LSP for sheep',
    priority: null,
    owner: {
      internal: "Henrik",
      external: null,
    },
    financier: null,
    goal: "Start selling LSP to sheep farmers",
    purpose: null,
    currentMileStone: null,
    deadline: null,
    status: "Unknown",
  },
  {
    name: 'South America',
    priority: null,
    owner: {
      internal: null,
      external: null,
    },
    financier: null,
    goal: "Expanding to South America",
    purpose: null,
    currentMileStone: null,
    deadline: null,
    status: "Unknown",
  }
]