import { useQuery } from 'react-query';
import api from 'api';
const { getBreedsBySpecies } = api;

export function useBreedsBySpecies(speciesId: string | number) {

  return useQuery(
    ['get-breeds-by-species', speciesId],
    getBreedsBySpecies,
  );

}
