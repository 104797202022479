import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { AddNewFarmParams } from 'types';
const {
  getProdplaceByFarm,
  getFarm,
  updateFarm,
  disableFarm,
  reEnableFarm,
  addNewFarm,
  refetchFarmFromCDB,
} = api;

export function useGetFarm(farmId: number) {

  return useQuery(
    ['get-farm', farmId], 
    getFarm,
    {
      enabled: !!farmId,
    }
  );

}

export const useProdplacesByFarm = () => useMutation((farmId: number | null) => getProdplaceByFarm(farmId))

export const useUpdateFarm = () => useMutation((params: any) => updateFarm(params));

export const useDisableFarm = () => useMutation((id: number) => disableFarm(id));

export const useReEnableFarm = () => useMutation((id: number) => reEnableFarm(id));

export const useAddNewFarm = () => useMutation((params: any) => addNewFarm(params));

export const useRefetchFarmCDB = () => useMutation((farmId: number) => refetchFarmFromCDB(farmId))
