import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

type Token = string | null;

export interface IInitialState {
  token: Token,
}

const initialState: IInitialState = {
  token: null,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState: <IInitialState>{
    token: null,
  },
  reducers: {
    setToken: (state, action: PayloadAction<Token>) => {
        
      console.log('REDUCER: ', state, action);
      state.token = action.payload;

    }
  },
});

export const contextSelector = (state: any) => state.context;

export const { setToken } = loginSlice.actions;

export const selectToken = createSelector(
  contextSelector,
  (state) => state.token,
);

export default loginSlice.reducer;
