
import { useEffect, useState, useMemo } from 'react';
import { Spin, Col, Card, List, Row, Typography, Dropdown, Menu, message } from 'antd';
import { HiDotsHorizontal } from 'react-icons/hi';
import useAccount from './useAccount';
import SimpleForm from 'components/SimpleForm';
import { map } from 'lodash';
import accountFields from 'pages/accounts/accountFields';
import { AccountFormItem } from 'types';
import GeneralInfoCard from './components/GeneralInfoCard';
import FarmsCard from './components/FarmsCard';
import GroupsCard from './components/GroupsCard';

export default function AccountPage() {

  const [isEditing, setIsEditing] = useState(false);

  const {
    isFetchingAccount,
    hasFetchedAccount,
    account,
    refetchAccount,
    isDisablingAccount,
    hasDisabledAccount,
    disableAccount,
    isReEnablingAccount,
    hasReEnabledAccount,
    reEnableAccount,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isUpdatingAccount,
    hasUpdatedAccount,
    updateAccount,
    isAddingFarmToAccount,
    hasAddedFarmToAccount,
    addFarmToAccount,
    isRemovingFarmFromAccount,
    hasRemovedFarmFromAccount,
    removeFarmFromAccount,
    isAddingGroupToAccount,
    hasAddedGroupToAccount,
    addGroupToAccount,
    isRemovingGroupFromAccount,
    hasRemovedGroupFromAccount,
    removeGroupFromAccount,
    isFetchingFarms,
    hasFetchedFarms,
    farms,
    refetchFarms,
    isFetchingGroups,
    hasFetchedGroups,
    groups,
  } = useAccount();
  
  useEffect(() => {

    const key = "status";

    if (isDisablingAccount) {

       message.loading({
         content: 'Disabling account',
         key,
       })

    }

    if (isReEnablingAccount) {

      message.loading({
        content: 'Enabling account',
        key,
      })

    }

    if (isUpdatingAccount) {

      message.loading({
        content: 'Updating account',
        key,
      })

    }

    if (hasReEnabledAccount || hasDisabledAccount || hasUpdatedAccount) {

      message.success({
        content: 'Done!',
        key,
      })
      
    }

    if (hasUpdatedAccount) {

      setIsEditing(false);

    }

  }, [
    isDisablingAccount,
    hasDisabledAccount,
    isReEnablingAccount,
    hasReEnabledAccount,
    hasUpdatedAccount,
    isUpdatingAccount,
  ])

  const formatedCountries = useMemo(() => map(countries, ({country, id}) => {
    return {
      label: country,
      value: id,
    }
  }), [countries]);

  if (isFetchingAccount) {

    return (
      <Spin spinning={isFetchingAccount} />
    )

  }

  const { 
    email,
    firstName,
    lastName,
    address1,
    address2,
    city,
    countryName,
    district,
    companyName,
    phone,
    id,
    mobilePhone,
    isActive 
  } = account;

  const actionsMenu = (
    <Menu>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            setIsEditing(true);

          }}
        >
          Edit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            isActive ? disableAccount({id}) : reEnableAccount({id});

          }}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </a>
      </Menu.Item>
    </Menu>
  )

  const onFinishUpdate = ({
    email,
    alternateEmail,
    firstName,
    lastName,
    phone,
    countryId,
    companyName,
    address1,
    address2,
    city,
    district,
    zipCode,
    billCountryId,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingDistrict,
    billingZipCode,
  }: AccountFormItem) => {
    
    const formatedData = {
      addressData: {
        address1,
        address2,
        city,
        district,
        zipCode
      },
      alternateEmail,
      billAddressData: {
        address1: billingAddress1,
        address2: billingAddress2,
        city: billingCity,
        district: billingDistrict,
        zipCode: billingZipCode,
      },
      billCountryId,
      companyName,
      countryId,
      email,
      firstName,
      lastName,
      login: email,
      id: account.id,
    }

    updateAccount(formatedData);
    
  }

  if (isEditing) {

    return (
      <SimpleForm 
        initialValues={account}
        fields={accountFields(formatedCountries)}
        onFinish={(values: AccountFormItem) => onFinishUpdate(values)}
        backAction={() => setIsEditing(false)}
        header={`Update user ${email}`}
      />
    );

  }

  return (
    <Spin spinning={isFetchingAccount}>
      <Row gutter={[20, 20]}>
        <Col 
          xs={24}
          style={{
            marginTop: 20,
          }}
        >
          <Typography.Title level={2}>
            {email}
            <Dropdown
              overlay={actionsMenu}
              trigger={['click']}
            >
              <HiDotsHorizontal
                style={{
                  cursor: 'pointer',
                  marginTop: 1,
                  marginLeft: 10,
                  marginBottom: -3,
                  width: 25,
                  height: 25,
                }}
              />
            </Dropdown>
          </Typography.Title>
          
        </Col>
        <Col 
          xs={24} 
          md={12}
        >
          <GeneralInfoCard 
            account={account}
          />
        </Col>
        <Col 
          xs={24} 
          md={12}
        >
        <GroupsCard
            account={account}
            groups={groups}
            isFetchingAccount={isFetchingAccount}
            isFetchingGroups={isFetchingGroups}
            isAddingGroupToAccount={isAddingGroupToAccount}
            hasAddedGroupToAccount={hasAddedGroupToAccount}
            addGroupToAccount={addGroupToAccount}
            refetchAccount={refetchAccount}
            isRemovingGroupFromAccount={isRemovingGroupFromAccount}
            hasRemovedGroupFromAccount={hasRemovedGroupFromAccount}
            removeGroupFromAccount={removeGroupFromAccount}
          />
        </Col>
        <Col xs={24} md={12}>
          <FarmsCard
            isAddingFarmToAccount={isAddingFarmToAccount}
            hasAddedFarmToAccount={hasAddedFarmToAccount}
            addFarmToAccount={addFarmToAccount}
            account={account}
            farms={farms}
            isFetchingFarms={isFetchingFarms}
            isFetchingAccount={isFetchingAccount}
            refetchAccount={refetchAccount}
            isRemovingFarmFromAccount={isRemovingFarmFromAccount}
            hasRemovedFarmFromAccount={hasRemovedFarmFromAccount}
            removeFarmFromAccount={removeFarmFromAccount}
          />
        </Col>
      </Row>
    </Spin>
  );

}
