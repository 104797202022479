
import { service } from 'api';
import { AddNewFarmParams, ConnectServiceGatewayWithFarmParams } from 'types';

export const v1 = '/api/v1';

export const getAllFarms = () => service(`${v1}/farms/admin-all`, {method: 'GET'});

export const getFarm = ({queryKey}: {queryKey: [string, {farmId: number}]}) => {

  const [_key, farmId] = queryKey;

  return service(`${v1}/farms/admin-get?id=${farmId}`, {method: 'GET'});

}

export const getProdplaceByFarm = (farmId: number | null) => service(`${v1}/prod-places/list`, {method: 'GET', params: {farmId}});

export const addNewFarm = (params: any) => service('/api/v1/farms/admin-add', {method: 'POST', data: params});

export const disableFarm = (id: number) => service('/api/v1/farms/admin-disable', {method: 'POST', params: {id}});

export const reEnableFarm = (id: number) => service('/api/v1/farms/admin-re-enable', {method: 'POST', params: {id}});

export const updateFarm = (params: any) => service('/api/v1/farms/admin-edit', {method: 'PUT', data: params});

export const addServiceGatewayToFarm = (params: ConnectServiceGatewayWithFarmParams) => service('/api/v1/farms/admin-add-sg', {method: 'POST', params});

export const removeServiceGatewayFromFarm = (params: ConnectServiceGatewayWithFarmParams) => service('/api/v1/farms/admin-remove-sg', {method: 'POST', params});

export const refetchFarmFromCDB = (farmId: number) => service('/api/v1/farms/admin-refetch-farm', {method: 'POST', params: {farmId}})