
import { useMutation, useQuery } from 'react-query';
import api from 'api';
import { AddScaleParams, UpdateScaleParams } from 'types';

const { 
  getScale,
  getScales,
  getScaleTypes,
  updateScale,
  disableScale,
  reEnableScale,
  addNewScale,
} = api;

export function useGetScale(scaleId: number) {

  return useQuery(
    ['get-scale', scaleId], 
    getScale,
    {
      enabled: !!scaleId,
    }
  );

}

export const useGetScales = () => useQuery('get-scales', getScales);

export const useUpdateScale = () => useMutation((params: UpdateScaleParams) => updateScale(params));

export const useDisableScale = () => useMutation((id: number) => disableScale(id));

export const useReEnableScale = () => useMutation((id: number) => reEnableScale(id));

export const useAddNewScale = () => useMutation((params: AddScaleParams) => addNewScale(params));
