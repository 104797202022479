
import { useLogin } from 'hooks/useLogin';


export default function useLoginPage() {

  const { isSuccess: hasLoggedIn, isLoading: isLoggingIn, data: loginResults, isError: loginFailed, mutate: login } = useLogin();

  return {
    hasLoggedIn,
    isLoggingIn,
    loginResults,
    loginFailed,
    login,
  }

}
