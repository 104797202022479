

import { useGetAnimalsWeights } from "hooks/useAnimals";
import { useGetScales } from "hooks/useScales";

export default function useAnimalsPage () {

  const { isLoading: isFetchingWeights, isSuccess: hasFetchedWeights, data: weights = {}, mutate: fetchWeights} = useGetAnimalsWeights();
  const { isLoading: isFetchingScales, isSuccess: hasFetchedScales, data: scales = {}} = useGetScales();

  return {
    isFetchingWeights,
    hasFetchedWeights,
    weights,
    fetchWeights,
    isFetchingScales,
    hasFetchedScales,
    scales,
  }

}
