type SelectOption = {
  label: string,
  value: number,
}

// serviceGatewayId,


export default function (scaleTypes: Array<SelectOption>, weightingTypes: Array<SelectOption>, serviceGateways: Array<SelectOption>) { 
  
  return [
    {
      name: "id",
      props: {
        hidden: true,
      }
    },
    {
      name: "name",
      label: "Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "displayName",
      label: "Display Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "notes",
      label: "Notes",
      type: "textarea",
    },
    {
      name: "serialNumber",
      label: "Serial number",
      type: "input",
    },
    {
      name: "scaleTypeId",
      label: "Scale type",
      type: "select",
      props: {
        required: true,
      },
      options: scaleTypes,
    },
    {
      name: "serviceGatewayId",
      label: "Service gateway",
      type: "select",
      props: {
        required: true,
      },
      options: serviceGateways,
    },
    {
      name: "weightingType",
      label: "Weighing type",
      type: "select",
      props: {
        required: true,
      },
      options: weightingTypes,
    },
  ];

}