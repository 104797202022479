import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from '../history';
import loginReducer from 'features/login/loginSlice';

const store = configureStore({
  reducer: {
    router: connectRouter(history),
    login: loginReducer,
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: false }),
    routerMiddleware(history),
  ],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
