import { useEffect, useState } from 'react';
import moment from 'moment';
import { Scale, ScaleType, ServiceGateway, UpdateScaleParams, AddScaleParams } from 'types';
import { UseMutateFunction } from 'react-query';
import { Row, Col, Card, Table, Typography, Spin, Input, Menu, List, Dropdown, message, Button, Select } from 'antd';
import { map, filter, find } from 'lodash';
import { alarmOptions, getValuesForSelect } from 'utils';
import { HiDotsHorizontal } from 'react-icons/hi';
import BackLink from 'components/BackLink';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import { useHistory, Link } from 'react-router-dom';
import useServiceGatewayPage from './useServiceGatewayPage';
import ListItemWithTitle from 'components/ListItemWithTitle';
import SimpleForm from 'components/SimpleForm';


export default function ServiceGatewayPage () {

  const {
    serviceGatewayId,
    isFetchingServiceGateway,
    hasFetchedServiceGateway,
    serviceGateway,
    refetchServiceGateway,
    isDisablingServiceGateway,
    hasDisabledServiceGateway,
    disableServiceGateway,
    isReEnablingServiceGateway,
    hasReEnabledServiceGateway,
    reEnableServiceGateway,
    isUpdatingServiceGateway,
    hasUpdatedServiceGateway,
    updateServiceGateway,
    isFetchingFarms,
    hasFetchedFarms,
    allFarms,
    isAddingServiceGatewayToFarm,
    hasAddedServiceGatewayToFarm,
    addServiceGatewayToFarm,
    useAddServiceGatewayToFarm,
    isRemovingServiceGatewayToFarm,
    hasRemovedServiceGatewayToFarm,
    removeServiceGatewayToFarm,
    isFetchingServiceGatewayInfo,
    hasFetchedServiceGatewayInfo,
    fetchedServiceGatewayInfo,
    isFetchingServiceGatewayLog,
    hasFetchedServiceGatewayLog,
    fetchedServiceGatewayLog,
    serviceGatewayLog
  } = useServiceGatewayPage();

  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [assignNewFarm, setAssignNewFarm] = useState(null);

  useEffect(() => {

    if (hasFetchedServiceGateway)  {

      fetchedServiceGatewayLog({serviceGatewayName: serviceGateway.sgId});

    }   

  }, [hasFetchedServiceGateway]);
  
  useEffect(() => {

    const key = "status";

    if (isReEnablingServiceGateway) {

       message.loading({
         content: 'Enabling service gateway',
         key,
       })

    }

    if (isDisablingServiceGateway) {

      message.loading({
        content: 'Disabling service gateway',
        key,
      })

    }

    if (isUpdatingServiceGateway) {

      message.loading({
        content: 'Updating service gateway',
        key,
      })

    }

    if (isAddingServiceGatewayToFarm) {

      message.loading({
        content: 'Assigning service gateway to farm',
        key,
      })

    }


    if (isRemovingServiceGatewayToFarm) {

      message.loading({
        content: 'Removing service gateway to farm',
        key,
      })

    }

    if (
      hasReEnabledServiceGateway || 
      hasDisabledServiceGateway || 
      hasUpdatedServiceGateway || 
      hasAddedServiceGatewayToFarm || 
      hasRemovedServiceGatewayToFarm
    ) {

      refetchServiceGateway();

      message.success({
        content: 'Done!',
        key,
      });

    }

    if (hasUpdatedServiceGateway) {

      setIsEditing(false);

    }

  }, [
    isReEnablingServiceGateway,
    hasReEnabledServiceGateway,
    isDisablingServiceGateway,
    hasDisabledServiceGateway,
    isUpdatingServiceGateway,
    hasUpdatedServiceGateway,
    isAddingServiceGatewayToFarm,
    hasAddedServiceGatewayToFarm,
    isRemovingServiceGatewayToFarm,
    hasRemovedServiceGatewayToFarm,
  ])

  const {
    alarmActivationTime,
    farms,
    id,
    isActive,
    isAlarmActivated,
    lastContact,
    notes,
    scales,
    secret,
    sgFileNumber,
    sgId,
  } = serviceGateway;

  const actionsMenu = (
    <Menu>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            setIsEditing(true);

          }}
        >
          Edit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();

            isActive ? disableServiceGateway(id) : reEnableServiceGateway(id);

          }}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </a>
      </Menu.Item>
    </Menu>
  )

  const onFinishUpdate = (values: any) => {

    const formatedValues = {
      ...values,
      id: serviceGateway.id,
    }

    updateServiceGateway(formatedValues);

  }

  const getFarmIdByName = (name: string) => {

    return find(allFarms, (farm) => farm.farmName == name)?.id;

  }

  if (isEditing) {

    const fields = [
      {
        name: "sgId",
        label: "Name",
        type: "input",
      },
      // {
      //   name: "isAlarmActivated",
      //   label: "Is alarm active?",
      //   type: 'switch',
      // },
      // {
      //   name: "alarmActivationTime",
      //   label: "Alarm activation time",
      //   type: 'select',
      //   options: alarmOptions,
      // },
      {
        name: "notes",
        label: "Notes",
        type: 'textarea',
      },
      {
        name: "secret",
        label: "Secret",
        type: 'input',
      }
    ]

    return (
      <SimpleForm
        initialValues={serviceGateway}
        fields={fields}
        onFinish={onFinishUpdate}
        backAction={() => setIsEditing(false)}
        header="Edit service gateway"
      />
    );

  }

  const renderFarmItem = (farm: string) => {

    return (
      <>
        <Typography.Text>{farms ? farms : 'No farm set'}</Typography.Text>
        <Button
          danger
          style={{
            marginLeft: 20,
          }}
          onClick={() => {

            const farmId = getFarmIdByName(farm);
            const {id: serviceGatewayId} = serviceGateway;

            removeServiceGatewayToFarm({farmId, serviceGatewayId});

          }}
        >
          Remove
        </Button>
      </>
    );

  }

  const renderFarms = () => {
    
    return renderFarmItem(farms);

    // if (farms.length > 1) {
    
    //   return map(farms, (farm) => renderFarmItem(farm));

    // }

  }

  const parseLogs = (logString) => {

    if (!logString) {

      return [];

    }
    return logString.trim().split('\n').reverse(); // Dela upp vid radbrytningar och vänd ordningen
  };

  return (
    <Spin spinning={isFetchingServiceGateway}>
      <Row gutter={[20, 20]}>
        <Col 
          xs={24}
          style={{
            marginTop: 20,
          }}
        >
          <Typography.Title level={2}>
            {sgId}
            <Dropdown
              overlay={actionsMenu}
              trigger={['click']}
            >
              <HiDotsHorizontal
                style={{
                  cursor: 'pointer',
                  marginTop: 1,
                  marginLeft: 10,
                  marginBottom: -3,
                  width: 25,
                  height: 25,
                }}
              />
            </Dropdown>
          </Typography.Title>  
        </Col>
        <Col 
          xs={24}
          style={{
            marginTop: -20,
            marginBottom: 20,
          }}
        >
          <Link
            to="/service-gateways"
          >
            <BackLink
              text="Back to all"
            />
          </Link>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Info" bordered={false}>
              <List
                bordered={false}
              >
                <ListItemWithTitle 
                  title={'Name'}
                  items={[
                    sgId,
                  ]}
                />
                <ListItemWithTitle 
                  title={'Status'}
                  items={[
                    <ActiveStatusBadge isActive={isActive} />,
                  ]}
                />
                <ListItemWithTitle 
                  title={'Last contact'}
                  items={[
                    moment(lastContact).fromNow(),
                    moment(lastContact).format('YYYY-MM-DD hh:mm:ss'),
                  ]}
                />
                <ListItemWithTitle 
                  title={'Scales'}
                  items={[
                    // @ts-ignore
                    map(scales, (scale, i) => <div>{scale}</div>),
                  ]}
                />
                <ListItemWithTitle 
                  title={'Farms'}
                  items={[
                    farms,
                  ]}
                />
                <ListItemWithTitle 
                  title={'Notes'}
                  items={[
                    notes,
                  ]}
                />
                {/* <ListItemWithTitle 
                  title={'Alarm'}
                  items={[
                    <strong
                      style={{
                        color: isAlarmActivated ? 'green' : 'red',
                      }}
                    >
                      {isAlarmActivated ? 'Active' : 'Not active'}
                    </strong>,
                    `Activation time: ${alarmActivationTime/3600} days`,
                  ]}
                /> */}
              </List>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card 
            title="Assigned farm"
            bordered={false}
          >
            {(farms && farms.length) ? 

              renderFarms()
              
              : 

              <>
                <Select
                  showSearch
                  onChange={(value: any) => {

                    setAssignNewFarm(value);

                  }}
                  placeholder="Search for farm"
                  options={getValuesForSelect(allFarms, 'farmName', 'id')}
                  style={{
                    width: '100%',
                    marginBottom: 10,
                  }}
                  filterOption={(input, option) => {
                    // @ts-ignore
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  filterSort={(optionA, optionB) =>
                    // @ts-ignore
                   optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                  }
                />
                <Button
                  type="primary"
                  disabled={!assignNewFarm}
                  onClick={() => {
        
                    const {id: serviceGatewayId} = serviceGateway;
                    const farmId = assignNewFarm;

                    if (farmId) {
                    
                      addServiceGatewayToFarm({farmId, serviceGatewayId});
                    
                    }
        
                  }}
                >
                  Assign
                </Button>
              </>
            }
          </Card>
        </Col>
        <Col xs={24}>
          <Card 
            title="Log" 
            bordered={false}
            style={{
              marginTop: 20,
            }}
          >
            <List
              loading={isFetchingServiceGatewayLog}
              bordered={false}
            >
              {serviceGatewayLog ? 
                <List.Item
                  style={{
                    backgroundColor: "black",
                    // color: "green",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    textAlign: "left",
                  }}
                >
                  {parseLogs(serviceGatewayLog).map((log, index) => {
                    
                      const dateMatch = log.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);
                      const date = dateMatch ? dateMatch[0] : '';
                      const message = log.replace(date, ''); // Ta bort datum från loggtexten
              
                      return (
                        <div
                          key={index}
                          style={{
                            padding: '0.5rem',
                            marginBottom: '0.5rem',
                            borderRadius: '5px',
                            color: log.includes('ERROR') ? 'red' : 'green',
                          }}
                        >
                          <strong>{date}</strong> {message}
                        </div>
                      );

                  })}

                </List.Item>
              : null}
            </List>
          </Card>
        </Col>
      </Row>
    </Spin>
  );

}
