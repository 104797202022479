
import { service } from '.';
import { AccountItem } from 'types';

export const v1 = '/api/v1';

export const getAccounts = () => service('/api/v1/accounts/admin-all', {method: 'GET'});

export const getAccount = ({queryKey}: {queryKey: [string, string]}) => {

  const [_key, accountId] = queryKey;

  return service(`/api/v1/accounts/admin-get`, {method: 'GET', params: {accountId}})
  
};

export const addAccount = (params: AccountItem) => service(`${v1}/accounts/admin-add`, {method: 'POST', data: params});

export const addFarmToAccount = (params: {accountId: number, farmId: number}) => service(`${v1}/accounts/admin-add-farm`, {method: 'POST', params});

export const removeFarmFromAccount = (params: {accountId: number, farmId: number}) => service(`${v1}/accounts/admin-remove-farm`, {method: 'POST', params});

export const disableAccount = (params: {id: number}) => service(`${v1}/accounts/admin-disable`, {method: 'POST', params});

export const editAccount = (params: AccountItem) => service(`${v1}/accounts/admin-edit`, {method: 'PUT', data: params});

export const reEnableAccount = (params: {id: number}) => service(`${v1}/accounts/admin-re-enable`, {method: 'POST', params});

export const addGroupToAccount = (params: {accountId: number, groupId: number}) => service('/api/v1/accounts/admin-add-group', {method: 'POST', params});

export const removeGroupFromAccount = (params: {accountId: number, groupId: number}) => service('/api/v1/accounts/admin-remove-group', {method: 'POST', params});


