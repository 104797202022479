import React, { useState, useEffect } from 'react';
import { Col, Input, Row, Spin, Table, Typography, Button, Switch } from 'antd';
import { colors, highlightText } from 'utils';
import { map, filter, sumBy } from 'lodash';
import { ProductionPlace } from 'types';
import { useHistory } from "react-router-dom";
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import HandleProductionPlaceForm from 'components/HandleProductionPlaceForm';
import TableWithRowHover from 'components/TableWithRowHover';

const { Title } = Typography;

type AllProductionPlacesParams = {
  productionPlaces: Array<ProductionPlace>,
  isFetchingProductionPlaces: boolean,
  hasFetchedProductionPlaces: boolean,
  refetchProductionPlaces: () => void,
  searchText: string,
  setSearchText: (args0: string) => void,
}

export default function AllProductionPlaces ({
  productionPlaces,
  isFetchingProductionPlaces,
  hasFetchedProductionPlaces,
  refetchProductionPlaces,
  searchText,
  setSearchText
}: AllProductionPlacesParams) {

  const history = useHistory();
  const [addNew, setAddNew] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const storedPagination = localStorage.getItem('LSP-admin-pps-list-pagination');
  const {page: initialPage, pageSize: initialPageSize} = storedPagination ? JSON.parse(storedPagination) : {page: 1, pageSize: 100};
  const storedOnlyActive = localStorage.getItem('LSP-admin-active-pps-only');
  const [showOnlyActive, setShowOnlyActive] = useState(storedOnlyActive ? JSON.parse(storedOnlyActive) : true);

  useEffect(() => {

    if (initialPage > 1) {

      setPageIndex(initialPageSize * (initialPage - 1));

    }

  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const text = event.target.value;
    const searchText = text.length >= 1 ? text : '';
    
    setSearchText(searchText);

  }

  const allProductionPlaces = showOnlyActive ? filter(productionPlaces, (farm: ProductionPlace) => farm.isActive) : productionPlaces;

  const filteredProductionPlaces: Array<ProductionPlace> = filter(allProductionPlaces, ({name, code, farmName}: ProductionPlace) => {

    const search = searchText.toLowerCase();
    
    return name.toLowerCase().indexOf(search) > -1 || code.toLowerCase().indexOf(search) > -1 || farmName.toLowerCase().indexOf(search) > -1;

  });

  const dataSource = map(filteredProductionPlaces, (productionPlace: ProductionPlace) => {

    return {
      ...productionPlace,
      key: productionPlace.id,
    };

  });

  const columns = [
    {
      title: 'Row',
      key: 'row',
      render: (text: string, record: ProductionPlace, i: number) => {

        return pageIndex + (i + 1);

      },
      width: 30,
    },
    {
      title: `Code`,
      dataIndex: 'code',
      sorter:(a: ProductionPlace, b: ProductionPlace) => a.code?.localeCompare(b?.code),
      render: (text: string) => highlightText(text, searchText),
      width: 120,
    },
    {
      title: `Name`,
      dataIndex: 'name',
      sorter:(a: ProductionPlace, b: ProductionPlace) => ((a.name.length && b.name.length) && (a.name !== '-' && b.name !== '-')) ? a.name?.localeCompare(b?.name) : false,
      render: (text: string) => highlightText(text, searchText),
      width: 250,
    },
    {
      title: `Farm`,
      dataIndex: 'farmName',
      sorter: {
        compare: (a: ProductionPlace, b: ProductionPlace) => {
          
          if (a.farmName < b.farmName) {
            return -1;
          }
          if (a.farmName > b.farmName) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
      render: (text: string) => highlightText(text, searchText),
      width: 250,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (text: boolean) => <ActiveStatusBadge isActive={text} />,
      // @ts-ignore
      sorter:(a: ProductionPlace, b: ProductionPlace) => (a.isActive - b.isActive),
      width: 180,
    }
  ];

  if (addNew) {

    return (
      <HandleProductionPlaceForm 
        initialValues={null}
        backAction={() => setAddNew(false)}
        action={'add'}
        refetch={refetchProductionPlaces}
      />
    );

  }

  const totalWidth = sumBy(map(columns, (column) => column.width));

  return (
    <Spin spinning={isFetchingProductionPlaces}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            onClick={() => {

              setAddNew(true);

            }}
          >
            Add new
          </Button>
        </Col>
        <Col span={12}>
          <Input
            onChange={onChange}
            placeholder={'Filter by any value'}
          />
          <Title 
            level={5} 
            style={{
              marginTop: 20,
            }}
          >Showing: {dataSource.length}/{productionPlaces.length}</Title>
        </Col>
        <Col span={24}>
          <strong
            style={{
              display: 'block',
              marginBottom: 5,
            }}
          >Only active</strong>
          <Switch 
            checked={showOnlyActive}
            onChange={(value) => {

              localStorage.setItem('LSP-admin-active-pps-only', JSON.stringify(value));
              setShowOnlyActive(value)
            
            }}
          />
        </Col>
        <Col span={24}>
          <TableWithRowHover
            dataSource={dataSource}
            // @ts-ignore
            columns={columns}
            scroll={{
              x: totalWidth,
            }}
            onRow={(record, rowIndex) => {
              return {
                // @ts-ignore
                onClick: (event) => history.push(`production-places/${record.id}`),
              };
            }}
            pagination={{
              defaultCurrent: initialPage,
              defaultPageSize: initialPageSize,
              pageSizeOptions: ['20', '50', '100', '200'],
              position: ["topCenter", "bottomCenter"],
              onChange: (page: number, pageSize: number) => {

                localStorage.setItem('LSP-admin-pps-list-pagination', JSON.stringify({page, pageSize}));
    
                if (page > 1) {
    
                  setPageIndex(pageSize * (page - 1));
            
                } else {
    
                  setPageIndex(0);
    
                }
    
                return;
    
              }
            }}
          />
        </Col>
      </Row>
    </Spin>
  );

}