import React from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import { GiBinoculars } from 'react-icons/gi';

 const Lost = () => {
  return (
    <Spin spinning={false}>
      <Row gutter={[16, 16]}>
        <Col 
          span={24}
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 200,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <GiBinoculars 
            style={{
              fontSize: 120,
            }} 
          />
          <Typography.Title
            level={2}
            style={{
              marginTop: 20,
            }}
          >
            Did you get lost?
          </Typography.Title>
        </Col>
      </Row>
    </Spin>
  );
}

export default Lost;