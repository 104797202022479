import { 
  useGetProductionPlace,
  useUpdateProductionPlace,
  useAddNewProductionPlace,
  useDisableProductionPlace,
  useReEnableProductionPlace,
} from 'hooks/useProdplaces';
import { useGetFarms } from 'hooks/useFarms';
import { useParams } from 'react-router-dom';
import { useGetProductionOrientations, useGetSpecies } from 'hooks/useOther';

export default function useHandleProductionPlaceForm () {

  const { isLoading: isUpdatingProductionPlace, isSuccess: hasUpdatedProductionPlace, mutate: updateProductionPlace } = useUpdateProductionPlace();
  const { isLoading: isAddingNewProductionPlace, isSuccess: hasAddedNewProductionPlace, mutate: addNewProductionPlace } = useAddNewProductionPlace();
  const { isLoading: isFetchingFarms, isSuccess: hasFetchedFarms, data: farms = {} } = useGetFarms();
  const { isLoading: isFetchingProductionOrientations, isSuccess: hasFetchedProductionOrientations, data: productionOrientations = {} } = useGetProductionOrientations();
  const { isLoading: isFetchingSpecies, isSuccess: hasFetchedSpecies, data: species = {} } = useGetSpecies();
  
  return {
    isUpdatingProductionPlace,
    hasUpdatedProductionPlace,
    updateProductionPlace,
    isAddingNewProductionPlace,
    hasAddedNewProductionPlace,
    addNewProductionPlace,
    isFetchingFarms,
    hasFetchedFarms,
    farms,
    isFetchingProductionOrientations,
    hasFetchedProductionOrientations,
    productionOrientations,
    isFetchingSpecies,
    hasFetchedSpecies,
    species,
  }

}