import { Card, Table, Typography, Select, Button, Form, Spin, Input} from "antd";
import { map, find, filter, orderBy} from "lodash";
import { useMemo, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Account } from "types";
import { getValuesForSelect, highlightText } from 'utils';

type Group = {
  account: Array<string>,
  id: number,
  name: string,
}

type GroupsCardParams = {
  account: Account,
  groups: Array<{id: number, farmName: string, isActive: boolean}>,
  isFetchingAccount: boolean,
  isFetchingGroups: boolean,
  isAddingGroupToAccount: boolean,
  hasAddedGroupToAccount: boolean,
  addGroupToAccount: ({accountId, groupId}: {accountId: number, groupId: number}) => void,
  refetchAccount: () => void,
  isRemovingGroupFromAccount: boolean,
  hasRemovedGroupFromAccount: boolean,
  removeGroupFromAccount: ({accountId, groupId}: {accountId: number, groupId: number}) => void,
}

export default function GroupsCard({
  account,
  groups,
  isFetchingAccount,
  isFetchingGroups,
  isAddingGroupToAccount,
  hasAddedGroupToAccount,
  addGroupToAccount,
  refetchAccount,
  isRemovingGroupFromAccount,
  hasRemovedGroupFromAccount,
  removeGroupFromAccount,
}: GroupsCardParams) {

  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [form] = Form.useForm();

  const {
    userGroups,
  } = account;
  
  useEffect(() => {

    if (hasAddedGroupToAccount || hasRemovedGroupFromAccount) {

      refetchAccount();

    }

  }, [hasAddedGroupToAccount, hasRemovedGroupFromAccount])

  const formatedGroups = map(userGroups, (farm) => {
    return {
      key: farm.id,
      ...farm,
    }
  });

  const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text: string, record: any) => <Link to={`/accounts/groups`}>{text}</Link>,
        sorter:(a: any, b: any) => a.name.localeCompare(b.name)
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (record: any) => {

          return (
            <Button
              // type="primary"
              danger
              onClick={() => {

                removeGroupFromAccount({
                  accountId: account.id,
                  groupId: record.id,
                })

              }}
            >
              Remove
            </Button>
          )

        }
      }
    ];

  const isAdmin = find(userGroups, (group) => group.id === 1);

  const unassignedGroups = filter(groups, (group) => !find(userGroups, (userGroup) => userGroup.id === group.id));

  const renderExtra = () => {

    return (
      <a
        href="#"
        onClick={(e) => {

          e.preventDefault();

          const newValue = isAddingGroup ? false : true;

          setIsAddingGroup(newValue);

        }}
      >
        {isAddingGroup ? 'Done' : `Add more (${unassignedGroups.length})`}
      </a>
    );

  }

  const renderIsAddingGroup = () => {

    const selectValues = getValuesForSelect(unassignedGroups, 'name', 'id');

    return (
      <Form
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Form.Item
          name="newGroup"
          // initialValue={selectValues[0]}
        >
          <Select
            options={selectValues}
          />
        </Form.Item>
        <Button
          type="primary"
          disabled={!form.getFieldsValue()}
          onClick={() => {

            const { newGroup } = form.getFieldsValue();

            addGroupToAccount({
              accountId: account.id,
              groupId: newGroup,
            });

          }}
        >Add</Button>
      </Form>
    );

  }

  return (
    <Spin spinning={isFetchingAccount || isFetchingGroups}>
      <Card title="Groups" extra={renderExtra()}>
        {isAddingGroup && renderIsAddingGroup()}
        {isAdmin ? <Typography.Text
          strong
          style={{
            display: 'block',
            marginBottom: 10,
          }}  
        >
          {/* Has {userFarms.length} of {farms.length} assigned */}
        </Typography.Text> : null}
        <Table
          dataSource={orderBy(formatedGroups, ['name'])}
          columns={columns}
        />
      </Card>
    </Spin>
  );

} 