import React from 'react';
import {  Modal, Input, Form } from 'antd';

interface AddSpeciesProps {
  isVisible: boolean,
  handleOk: any,
  handleCancel: any,
  confirmLoading: boolean,
}

const AddSpeciesModal = ({isVisible, handleOk, handleCancel, confirmLoading}: AddSpeciesProps) => {

  const [form] = Form.useForm();

  const onOk = async () => {

    try {
      
      const values = await form.validateFields();
      handleOk(values);

    } catch (errorInfo) {
      
      console.error('Failed:', errorInfo);
    
    }

  }

  return (
    <Modal
      title="Add new species"
      visible={isVisible}
      onOk={onOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        name="new_species"
      >
        <Form.Item
          name="name"
          rules={[{ 
            required: true, 
            message: 'Please input the species name',
          }]}
        >
          <Input 
            placeholder="Name"
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[{ 
            required: true, 
            message: 'Please input the species description',
          }]}
        >
          <Input
            placeholder="Description"
            type="text"
          />
        </Form.Item>
      </Form>
    </Modal>
  );

}

export default AddSpeciesModal;