
import { useQuery, useMutation } from 'react-query';
import { UpdateScaleTypeParams, AddScaleTypeParams } from 'types';
import api from 'api';

const {
  getScaleTypes,
  updateScaleType,
  addNewScaleType
} = api;

export const useGetScaleTypes = () => useQuery('get-scale-types', getScaleTypes);

export const useUpdateScaleType = () => useMutation((params: UpdateScaleTypeParams) => updateScaleType(params));

export const useAddScaleType = () => useMutation((params: AddScaleTypeParams) => addNewScaleType(params));
