
import { useState } from 'react';
import { useDisableFarm, useProdplacesByFarm, useUpdateFarm, useReEnableFarm, useAddNewFarm, useGetFarm, useRefetchFarmCDB } from '../../hooks/useFarm';
import { useAddFarmToAccount, useGetAccounts, useRemoveFarmFromAccount } from 'hooks/useAccounts';
import { useGetCountries } from 'hooks/useCountries';
import useUrlParam from 'hooks/useUrlParam';
import { useParams } from 'react-router-dom';

export default function useFarmsPage () {
  
  //@ts-ignore
  let { id } = useParams();
  
  const { isLoading: isFetchingProdplaces, data: prodPlaces = {}, mutate: fetchProdplacesByFarm, isSuccess: hasFetchedProdplaces } = useProdplacesByFarm();
  const { isLoading: isUpdatingFarm, isSuccess: hasUpdatedFarm, mutate: updateFarm } = useUpdateFarm();
  const { isLoading: isAddingNewFarm, isSuccess: hasAddedNewFarm, mutate: addNewFarm } = useAddNewFarm();
  const { isLoading: isDisablingFarm, isSuccess: hasDisabledFarm, mutate: disableFarm } = useDisableFarm();
  const { isLoading: isReEnablingFarm, isSuccess: hasReEnabledFarm, mutate: reEnableFarm } = useReEnableFarm();
  const { isLoading: isFetchingCountries, isSuccess: hasFetchedCountries, data: countries = {} } = useGetCountries();
  const { isLoading: isFetchingFarm, isSuccess: hasFetchedFarm, data: farm = {}, refetch: refetchFarm} = useGetFarm(id);
  const { isLoading: isSettingFetchFarmFromCDB, isSuccess: hasSetFetchFarmFromCDB, isError: failedSettingFetchFromCDB, mutate: setFetchFarmFromCDB } = useRefetchFarmCDB();
  const { isLoading: isFetchingAllAccounts, isSuccess: hasFetchedAllAccounts, data: allAccounts = {} } = useGetAccounts();
  const { isLoading: isRemovingFarmFromAccount, isSuccess: hasRemovedFarmFromAccount, mutate: removeFarmFromAccount } = useRemoveFarmFromAccount();
  const { isLoading: isAddingFarmToAccount, isSuccess: hasAddedFarmToAccount, mutate: addFarmToAccount } = useAddFarmToAccount();

  return {
    farmId: id,
    isFetchingProdplaces,
    prodPlaces,
    fetchProdplacesByFarm,
    hasFetchedProdplaces,
    isUpdatingFarm,
    hasUpdatedFarm,
    updateFarm,
    isDisablingFarm,
    hasDisabledFarm,
    disableFarm,
    isReEnablingFarm,
    hasReEnabledFarm,
    reEnableFarm,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isAddingNewFarm,
    hasAddedNewFarm,
    addNewFarm,
    isFetchingFarm,
    hasFetchedFarm,
    farm,
    refetchFarm,
    setFetchFarmFromCDB,
    isSettingFetchFarmFromCDB,
    hasSetFetchFarmFromCDB,
    failedSettingFetchFromCDB,
    isFetchingAllAccounts,
    hasFetchedAllAccounts,
    allAccounts,
    isRemovingFarmFromAccount,
    hasRemovedFarmFromAccount,
    removeFarmFromAccount,
    isAddingFarmToAccount,
    hasAddedFarmToAccount,
    addFarmToAccount,
  }

}
