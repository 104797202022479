
import { useState } from 'react';
import { useGetFarms } from 'hooks/useFarms';
import { useDisableFarm, useProdplacesByFarm, useUpdateFarm, useReEnableFarm, useAddNewFarm } from '../../hooks/useFarm';
import { useGetCountries } from 'hooks/useCountries';
import useUrlParam from 'hooks/useUrlParam';

export default function useFarmsPage () {

  const { isLoading: isFetchingFarms, data: farms = {}, refetch: refetchFarms} = useGetFarms();
  const { isLoading: isAddingNewFarm, isSuccess: hasAddedNewFarm, mutate: addNewFarm } = useAddNewFarm();
  const { isLoading: isFetchingCountries, isSuccess: hasFetchedCountries, data: countries = {} } = useGetCountries();

  return {
    farms,
    isFetchingFarms,
    refetchFarms,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isAddingNewFarm,
    hasAddedNewFarm,
    addNewFarm,
  }

}
