import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Table, Typography, Button, Modal, Input, Form, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { PlusOutlined } from '@ant-design/icons';
import useSpecies from '../../hooks/useSpecies';
import { map, filter } from 'lodash';
import { Species as SpeciesType } from '../../types';
import { Link, useHistory } from 'react-router-dom';
import AddSpeciesModal from '../../components/AddSpeciesModal';
import { AddSpeciesDetails } from '../../types';
import { useMutation } from 'react-query';
import api from '../../api';
import BreedsTable from './components/breedsTable';

const { addSpecies } = api;
const { Title } = Typography;

const Species = () => {

  const history = useHistory();
  const { isLoading, data = {} } = useSpecies();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [newSpeciesValues, setNewSpeciesValues] = useState<AddSpeciesDetails>({name: null, description: null});
  // const mutate = useAddSpecies(newSpeciesValues, setNewSpeciesValues({name: null, description: null}));
  const addSpeciesMutation = useMutation(
    (params: AddSpeciesDetails) => addSpecies(params), {
      onSuccess: (data, variables, context) => {
        
        setIsModalVisible(false);
        message.success(`New species successfully added`);

      }
    }
  )
  
  useEffect(() => {})

  const allSpecies = data;

  if (!allSpecies) {

    return (
      <div></div>
    );

  }

  const dataSource = map(allSpecies, (species: SpeciesType) => {

    return {
      ...species,
      key: species.name,
    };

  });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: `Name`,
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: SpeciesType, b: SpeciesType) => {
          
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (text: string, record: SpeciesType) => {

    //     return (
    //       <a
    //         type="primary"
    //         onClick={() => history.push(`species/${record.id}/breeds`)}
    //       >
    //         Show breeds
    //       </a>
    //     )

    //   }
    // }
    
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (formValues: AddSpeciesDetails) => {

    addSpeciesMutation.mutate(formValues);

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title>
            All species
          </Title>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showModal}
          >
            Add new species
          </Button>
          <AddSpeciesModal
            isVisible={isModalVisible}
            handleOk={handleOk} 
            handleCancel={handleCancel}
            confirmLoading={addSpeciesMutation.isLoading}
          />
        </Col>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            columns={columns}
            expandable={{
              expandedRowRender: (record: SpeciesType) => <BreedsTable speciesId={record.id} speciesName={record.name}/>,
              // rowExpandable: () => true,
            }}
          >
          </Table>
        </Col>
      </Row>
    </Spin>
  );
}

export default Species;