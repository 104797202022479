import { Table, Tag } from 'antd';
import styled from 'styled-components';
import { AccountListItem } from 'types';
import { colors, highlightText } from 'utils';
import { map, filter, sumBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import TableWithRowHover from 'components/TableWithRowHover';
import { useMemo, useEffect, useState } from 'react';

type AccountsTableParams = {
  dataSource: Array<AccountListItem>,
  searchText: string,
}

export default function AccountsTable ({dataSource, searchText}: AccountsTableParams) {

  const history = useHistory();
  const [pageIndex, setPageIndex] = useState(0);
  const storedPagination = localStorage.getItem('LSP-admin-accounts-list-pagination');
  const {page: initialPage, pageSize: initialPageSize} = storedPagination ? JSON.parse(storedPagination) : {page: 1, pageSize: 100};

  useEffect(() => {

    if (initialPage > 1) {

      setPageIndex(initialPageSize * (initialPage - 1));

    }

  }, []);

  const columns = [
    {
      title: 'Row',
      key: 'row',
      render: (text: string, record: AccountListItem, i: number) => {

        return pageIndex + (i + 1);

      },
      width: 30,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: {
        compare: (a: AccountListItem, b: AccountListItem) => {
          
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
      width: 50,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string, record: AccountListItem) => highlightText(text, searchText),
      sorter:(a: AccountListItem, b: AccountListItem) => a.email.localeCompare(b.email),
      width: 250,
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: string, record: AccountListItem) => highlightText(text, searchText),
      sorter:(a: AccountListItem, b: AccountListItem) => a.firstName?.localeCompare(b?.firstName),
      width: 150,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text: string, record: AccountListItem) => highlightText(text, searchText),
      sorter:(a: AccountListItem, b: AccountListItem) => a.lastName?.localeCompare(b?.lastName),
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (text: boolean) => <ActiveStatusBadge isActive={text} />,
      sorter:(a: any, b: any) => (a.isActive - b.isActive),
      width: 100,
    }
    // {
    //   title: 'Actions',
    //   dataIndex: '',
    //   key: 'actions',
    //   render: (text, record: Scale) => {

    //     return (
    //       <Dropdown
    //         overlay={actionsMenu(record)}
    //         trigger={['click']}
    //       >
    //         <HiDotsHorizontal
    //           style={{
    //             cursor: 'pointer',
    //             marginTop: 1,
    //             marginLeft: 10,
    //             width: 25,
    //             height: 30,
    //           }}
    //         />
    //       </Dropdown>
    //     )

    //   }
    // }
  ];

  const tableWidth = useMemo(() => sumBy(map(columns, (column) => column.width)), columns);

  return (
    <TableWithRowHover
      dataSource={dataSource}
      columns={columns}
      onRow={(record: AccountListItem, rowIndex: number) => {
              
        return {
          onClick: () => history.push(`/accounts/${record.id}`)
        }
      
      }}
      scroll={{
        x: tableWidth,
      }}
      pagination={{
        defaultCurrent: initialPage,
        defaultPageSize: initialPageSize,
        pageSizeOptions: ['20', '50', '100', '200'],
        position: ["topCenter", "bottomCenter"],
        onChange: (page: number, pageSize: number | undefined) => {

          localStorage.setItem('LSP-admin-accounts-list-pagination', JSON.stringify({page, pageSize}));

          if (page > 1) {

            setPageIndex(pageSize * (page - 1));
      
          } else {

            setPageIndex(0);

          }

          return;

        }
      }}
    />
  );

}