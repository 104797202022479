import { useGetAccounts, useAddNewAccount } from 'hooks/useAccounts';
import { useGetCountries } from 'hooks/useCountries';

export default function useAccountsPage () {

  const { isLoading: isFetchingAccounts, data: accounts, isSuccess: hasFetchedAccounts, refetch: refetchAccounts } = useGetAccounts();
  const { isLoading: isFetchingCountries, isSuccess: hasFetchedCountries, data: countries = {} } = useGetCountries();
  const { isLoading: isAddingNewAccount, isSuccess: hasAddedNewAccount, mutate: addNewAccount, data: newAccountData = {} } = useAddNewAccount();

  return {
    isFetchingAccounts,
    accounts,
    hasFetchedAccounts,
    refetchAccounts,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isAddingNewAccount,
    hasAddedNewAccount,
    addNewAccount,
    newAccountData,
  }

}