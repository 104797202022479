
import { useGetProdplaces } from '../../hooks/useProdplaces';

export default function useFarmsPage () {

  const { isLoading: isFetchingProductionPlaces, data: productionPlaces = {}, isSuccess: hasFetchedProductionPlaces, refetch: refetchProductionPlaces  } = useGetProdplaces();


  return {
    productionPlaces,
    isFetchingProductionPlaces,
    hasFetchedProductionPlaces,
    refetchProductionPlaces,
  }

}
 