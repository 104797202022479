
import { 
  useAddNewAccount,
  useGetAccount,
  useDisableAccount,
  useReEnableAccount,
  useAddFarmToAccount,
  useRemoveFarmFromAccount,
  useAddGroupToAccount,
  useRemoveGroupFromAccount,
  useEditAccount,
} from "hooks/useAccounts";
import { useGetFarms } from "hooks/useFarms";
import { useGetAllGroups } from "hooks/useGroups";
import { useGetCountries } from "hooks/useCountries";
import { useParams } from "react-router-dom";

export default function useAccount () {

  //@ts-ignore
  const { accountId } = useParams();

  const { isLoading: isFetchingAccount, isSuccess: hasFetchedAccount, data: account, refetch: refetchAccount } = useGetAccount(accountId);
  const { isLoading: isDisablingAccount, isSuccess: hasDisabledAccount, mutate: disableAccount } = useDisableAccount();
  const { isLoading: isReEnablingAccount, isSuccess: hasReEnabledAccount, mutate: reEnableAccount } = useReEnableAccount();
  const { isLoading: isUpdatingAccount, isSuccess: hasUpdatedAccount, mutate: updateAccount } = useEditAccount();
  const { isLoading: isFetchingCountries, isSuccess: hasFetchedCountries, data: countries = {} } = useGetCountries();
  const { isLoading: isAddingFarmToAccount, isSuccess: hasAddedFarmToAccount, mutate: addFarmToAccount } = useAddFarmToAccount();
  const { isLoading: isRemovingFarmFromAccount, isSuccess: hasRemovedFarmFromAccount, mutate: removeFarmFromAccount } = useRemoveFarmFromAccount();
  const { isLoading: isAddingGroupToAccount, isSuccess: hasAddedGroupToAccount, mutate: addGroupToAccount } = useAddGroupToAccount();
  const { isLoading: isRemovingGroupFromAccount, isSuccess: hasRemovedGroupFromAccount, mutate: removeGroupFromAccount } = useRemoveGroupFromAccount();
  const { isLoading: isFetchingFarms, isSuccess: hasFetchedFarms, data: farms, refetch: refetchFarms } = useGetFarms();
  const { isLoading: isFetchingGroups, isSuccess: hasFetchedGroups, data: groups } = useGetAllGroups();
  

  return {
    isFetchingAccount,
    hasFetchedAccount,
    account,
    refetchAccount,
    isDisablingAccount,
    hasDisabledAccount,
    disableAccount,
    isReEnablingAccount,
    hasReEnabledAccount,
    reEnableAccount,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isUpdatingAccount,
    hasUpdatedAccount,
    updateAccount,
    isAddingFarmToAccount,
    hasAddedFarmToAccount,
    addFarmToAccount,
    isRemovingFarmFromAccount,
    hasRemovedFarmFromAccount,
    removeFarmFromAccount,
    isAddingGroupToAccount,
    hasAddedGroupToAccount,
    addGroupToAccount,
    isRemovingGroupFromAccount,
    hasRemovedGroupFromAccount,
    removeGroupFromAccount,
    isFetchingFarms,
    hasFetchedFarms,
    farms,
    refetchFarms,
    isFetchingGroups,
    hasFetchedGroups,
    groups,
  }

}
