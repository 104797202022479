import { Card, Table, Typography, Select, Button, Form, Spin, Input} from "antd";
import { map, find, filter, orderBy} from "lodash";
import { useMemo, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Account } from "types";
import { getValuesForSelect, highlightText } from 'utils';

type Farm = {
  id: number,
  farmName: string,
  isActive: boolean,
}

type FarmsCardParams = {
  account: Account,
  farms: Array<Farm>,
  isFetchingAccount: boolean,
  isFetchingFarms: boolean,
  isAddingFarmToAccount: boolean,
  hasAddedFarmToAccount: boolean,
  addFarmToAccount: ({accountId, farmId}: {accountId: number, farmId: number}) => void,
  refetchAccount: () => void,
  isRemovingFarmFromAccount: boolean,
  hasRemovedFarmFromAccount: boolean,
  removeFarmFromAccount: ({accountId, farmId}: {accountId: number, farmId: number}) => void,
}

export default function FarmsCard({
  account,
  farms,
  isFetchingAccount,
  isFetchingFarms,
  isAddingFarmToAccount,
  hasAddedFarmToAccount,
  addFarmToAccount,
  refetchAccount,
  isRemovingFarmFromAccount,
  hasRemovedFarmFromAccount,
  removeFarmFromAccount,
}: FarmsCardParams) {

  const [isAddingFarm, setIsAddingFarm] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();

  const {
    userFarms,
    userGroups,
  } = account;
  
  useEffect(() => {

    if (hasAddedFarmToAccount || hasRemovedFarmFromAccount) {

      refetchAccount();

    }

  }, [hasAddedFarmToAccount, hasRemovedFarmFromAccount])

  const formatedFarms = map(userFarms, (farm) => {
    return {
      key: farm.id,
      ...farm,
    }
  });

  const columns = [
      {
        title: 'Name',
        dataIndex: 'farmName',
        render: (text: string, record: any) => <Link to={`/farms/${record.id}`}>{highlightText(text, searchText)}</Link>,
        sorter:(a: any, b: any) => a.farmName.localeCompare(b.farmName)
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (record: any) => {

          return (
            <Button
              // type="primary"
              danger
              onClick={() => {

                removeFarmFromAccount({
                  accountId: account.id,
                  farmId: record.id,
                })

              }}
            >
              Remove
            </Button>
          )

        }
      }
    ];

  const isAdmin = find(userGroups, (group) => group.id === 1);

  const unassignedFarms = filter(farms, (farm) => !find(userFarms, (userFarm) => userFarm.id === farm.id));

  const renderExtra = () => {

    return (
      <a
        href="#"
        onClick={(e) => {

          e.preventDefault();

          const newValue = isAddingFarm ? false : true;

          setIsAddingFarm(newValue);

        }}
      >
        {isAddingFarm ? 'Done' : `Add more (${unassignedFarms.length})`}
      </a>
    );

  }

  const dataSource: Array<Farm> = filter(formatedFarms, (farm: Farm) => {
  
    const { farmName } = farm;
  
    const search = searchText.toLowerCase();
    
    return farmName?.toLowerCase().indexOf(search) > -1;
  
  });

  const renderIsAddingFarm = () => {

    const selectValues = getValuesForSelect(unassignedFarms, 'farmName', 'id');

    return (
      <Form
        form={form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Form.Item
          name="newFarm"
          // initialValue={selectValues[0]}
        >
          <Select
            options={selectValues}
          />
        </Form.Item>
        <Button
          type="primary"
          disabled={!form.getFieldsValue()}
          onClick={() => {

            const { newFarm } = form.getFieldsValue();

            addFarmToAccount({
              accountId: account.id,
              farmId: newFarm,
            });

          }}
        >Add</Button>
      </Form>
    );

  }

  const renderSearch = () => {

    return (
      <>
        <Input
          placeholder="Find farm"
          style={{
            marginBottom: 10,
            marginTop: 20,
          }}
          allowClear
          onChange={(e) => {

            setSearchText(e.target.value);

          }}
        />
      </>
    )

  }

  return (
    <Spin spinning={isFetchingAccount || isFetchingFarms}>
      <Card title="Farms" extra={renderExtra()}>
        {isAddingFarm && renderIsAddingFarm()}
        {renderSearch()}
        {isAdmin ? <Typography.Text
          strong
          style={{
            display: 'block',
            marginBottom: 10,
          }}  
        >
          {/* Has {userFarms.length} of {farms.length} assigned */}
        </Typography.Text> : null}
        <Table
          dataSource={orderBy(dataSource, ['farmName'])}
          columns={columns}
        />
      </Card>
    </Spin>
  );

} 