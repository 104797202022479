import { useEffect, useState } from 'react';
import { Scale, ScaleType, ServiceGateway, UpdateServiceGatewayParams, AddServiceGatewayParams } from 'types';
import { UseMutateFunction } from 'react-query';
import { Row, Col, Card, Table, Typography, Tag, Spin, Input, Dropdown, Menu, Form, Select, Button, Checkbox, Switch, message } from 'antd';
import { map, filter, find, sumBy } from 'lodash';
import { useMemo } from 'react';
import { colors, highlightText, alarmOptions } from 'utils';
import { HiDotsHorizontal } from 'react-icons/hi';
import BackLink from 'components/BackLink';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { IoMdAddCircleOutline } from 'react-icons/io';
import SimpleForm from 'components/SimpleForm';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import { useHistory } from 'react-router-dom';
import TableWithRowHover from 'components/TableWithRowHover';

type ServiceGatewayTableParams = {
  serviceGateways: Array<ServiceGateway>,
  isFetchingServiceGateways: boolean,
  isUpdatingServiceGateway: boolean,
  hasUpdatedServiceGateway: boolean,
  updateServiceGateway: UseMutateFunction<any, unknown, UpdateServiceGatewayParams, unknown>,
  isDisablingServiceGateway: boolean,
  hasDisabledServiceGateway: boolean,
  disableServiceGateway: UseMutateFunction<unknown, unknown, number, unknown>
  isReEnableServiceGateway: boolean,
  hasReEnabledServiceGateway: boolean,
  reEnableServiceGateway: UseMutateFunction<unknown, unknown, number, unknown>
  refetchServiceGateways: () => void,
  isAddingNewServiceGateway: boolean,
  hasAddedNewServiceGateway: boolean,
  addNewServiceGateway: UseMutateFunction<any, unknown, AddServiceGatewayParams, unknown>,
}

export default function ScalesTable ({
  serviceGateways, 
  isFetchingServiceGateways,
  isUpdatingServiceGateway,
  hasUpdatedServiceGateway,
  updateServiceGateway,
  isDisablingServiceGateway,
  hasDisabledServiceGateway,
  disableServiceGateway,
  isReEnableServiceGateway,
  hasReEnabledServiceGateway,
  reEnableServiceGateway,
  refetchServiceGateways,
  isAddingNewServiceGateway,
  hasAddedNewServiceGateway,
  addNewServiceGateway,
}: ServiceGatewayTableParams) {

  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [isEditing, setIsEditing] = useState<{id: null | number}>({
    id: null,
  });
  const [addNew, setAddNew] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const storedPagination = localStorage.getItem('LSP-admin-sgs-list-pagination');
  const {page: initialPage, pageSize: initialPageSize} = storedPagination ? JSON.parse(storedPagination) : {page: 1, pageSize: 100};
  const storedOnlyActive = localStorage.getItem('LSP-admin-active-sgs-only');
  const [showOnlyActive, setShowOnlyActive] = useState(storedOnlyActive ? JSON.parse(storedOnlyActive) : true);

  const allSgs = showOnlyActive ? filter(serviceGateways, (serviceGateway: ServiceGateway) => serviceGateway.isActive) : serviceGateways;

  useEffect(() => {

    if (initialPage > 1) {

      setPageIndex(initialPageSize * (initialPage - 1));

    }

  }, []);

  useEffect(() => {

    if (hasUpdatedServiceGateway) {

        message.success('Service gateway was updated');

        refetchServiceGateways();

        setIsEditing({
          id: null,
        });

    }

  }, [hasUpdatedServiceGateway])

  useEffect(() => {

    const key = "status";

    if (isReEnableServiceGateway) {

       message.loading({
         content: 'Enabling service gateway',
         key,
       })

    }

    if (isDisablingServiceGateway) {

      message.loading({
        content: 'Disabling service gateway',
        key,
      })

    }

    if (isAddingNewServiceGateway) {

      message.loading({
        content: 'Adding new service gateway',
        key,
      })

    }

    if (hasReEnabledServiceGateway || hasDisabledServiceGateway || hasAddedNewServiceGateway) {

      refetchServiceGateways();

      message.success({
        content: 'Done!',
        key,
      })
      
      if (hasAddedNewServiceGateway) {

        setAddNew(false)

      }

    }

  }, [
    isReEnableServiceGateway,
    isDisablingServiceGateway,
    hasReEnabledServiceGateway,
    hasDisabledServiceGateway,
    isAddingNewServiceGateway,
    hasAddedNewServiceGateway,
  ])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const text = event.target.value;
    const searchText = text.length >= 1 ? text : '';
    
    setSearchText(searchText);

  }

  const serviceGatewaysWithKey = map(allSgs, (serviceGateway: ServiceGateway) => {
    
    return {
      key: serviceGateway.id,
      ...serviceGateway,
    }

  });

  const dataSource: Array<ServiceGateway> = filter(serviceGatewaysWithKey, (serviceGateway: ServiceGateway) => {

    const { sgId, notes } = serviceGateway;

    const search = searchText.toLowerCase();
    
    return sgId?.toLowerCase().indexOf(search) > -1 || notes?.toLowerCase().indexOf(search) > -1;

  });

  const columns = [
    {
      title: 'Row',
      key: 'row',
      render: (text: string, record: ServiceGateway, i: number) => {

        return pageIndex + (i + 1);

      },
      width: 30,
    },
    {
      title: 'Name (sg ID)',
      dataIndex: 'sgId',
      key: 'sgId',
      render: (text: string) => highlightText(text, searchText),
      sorter:(a: ServiceGateway, b: ServiceGateway) => a.sgId.localeCompare(b.sgId),
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (text: boolean) => <ActiveStatusBadge isActive={text} />,
      sorter:(a: any, b: any) => (a.isActive - b.isActive),
      width: 120,
      // sorter:(a: Scale, b: Scale) => a.isActive.localeCompare(b.isActive),
    },
    {
      title: 'Last contact',
      dataIndex: 'lastContact',
      key: 'lastContact',
      render: (text: string) => !moment(text).unix() ? '-' : `${`${moment(text).fromNow(true)} ago`} (${moment(text).format('YYYY-MM-DD HH:mm:ss')})`,
      sorter:(a: ServiceGateway, b: ServiceGateway) => moment(a.lastContact).unix() - moment(b.lastContact).unix(),
      width: 200,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (text: string) => highlightText(text, searchText),
      sorter:(a: ServiceGateway, b: ServiceGateway) => a.notes?.localeCompare(b.notes),
      width: 400,
    },
    // {
    //   title: 'Alarm',
    //   dataIndex: '',
    //   key: 'alarm',
    //   render: (text: string, record: ServiceGateway) => {

    //     return (
    //       <>
    //         <Switch defaultChecked={record.isAlarmActivated} disabled /> <br/>
    //       </>

    //     )

    //   },
    // },
  ];

  const onFinish = (values: any) => {

    updateServiceGateway(values);
    
  }

  const renderEditForm = (id: number) => {
    
    const currentServiceGateway = find(serviceGateways, (serviceGateway: ServiceGateway) => serviceGateway.id == id);

    return (
      <Card
        style={{
          marginTop: 20,
        }}
      >
        <Row gutter={[0, 20]}>
          <Col xs={24}>
            <a 
              href="#"
              onClick={(e) => {

                e.preventDefault();

                setIsEditing({
                  id: null,
                });

              }}
            >
              <BackLink
                text="Back"
              />
            </a>
          </Col>
          <Col xs={24}>
            <Typography.Title level={2}>
              Edit service gateway
            </Typography.Title>
          </Col>
          <Col xs={24} md={12}>
            <Form
              initialValues={currentServiceGateway}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                name="id"
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="sgId"
                label="Name (SG ID)"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="notes"
                label="Notes"
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                  name="isAlarmActivated"
                  label="Is alarm active?"
                >
                  <Switch defaultChecked={currentServiceGateway?.isAlarmActivated} />
              </Form.Item> */}
              <Form.Item
                  name="isActive"
                  label="Is active?"
                >
                  <Switch defaultChecked={currentServiceGateway?.isActive} />
              </Form.Item>
              {/* <Form.Item
                  name="alarmActivationTime"
                  label="Alarm activation time"
                >
                  <Select
                    options={alarmOptions}
                  />
              </Form.Item> */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    )

  }

  const onFinishAddNew = (values: any) => {

    addNewServiceGateway(values);

  }

  if (addNew) {

    const fields = [
      {
        name: "sgId",
        label: "Name",
        type: "input",
      },
      // {
      //   name: "isAlarmActivated",
      //   label: "Is alarm active?",
      //   type: 'switch',
      // },
      // {
      //   name: "alarmActivationTime",
      //   label: "Alarm activation time",
      //   type: 'select',
      //   options: alarmOptions,
      // },
      {
        name: "notes",
        label: "Notes",
        type: 'textarea',
      },
      {
        name: "secret",
        label: "Secret",
        type: 'input',
      }
    ]

    return (
      <SimpleForm
        fields={fields}
        onFinish={onFinishAddNew}
        backAction={() => setAddNew(false)}
        header="Add new service gateway"
      />
    );

  }

  if (isEditing.id) {

    return renderEditForm(isEditing.id);

  }

  const tableWidth = sumBy(map(columns, (column) => column.width)) 

  return (
    <Spin spinning={isFetchingServiceGateways}>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            onClick={() => {

              setAddNew(true);

            }}
          >
            Add new
          </Button>
        </Col>
        <Col 
          span={12}
          style={{
            maxWidth: 400
          }}
        >
          <strong>Filter</strong>
          <Input
            onChange={onChange}
            placeholder={'Filter by Name or Notes'}
          />
          <Typography.Title 
            level={5} 
            style={{
              marginTop: 20,
            }}
          >Showing: {dataSource.length}/{serviceGateways.length}</Typography.Title>
        </Col>
        <Col span={24}>
          <strong
            style={{
              display: 'block',
              marginBottom: 5,
            }}
          >Only active</strong>
          <Switch 
            checked={showOnlyActive}
            onChange={(value) => {

              localStorage.setItem('LSP-admin-active-sgs-only', JSON.stringify(value));
              setShowOnlyActive(value)
            
            }}
          />
        </Col>
        <Col span={24}>
          <TableWithRowHover
            dataSource={dataSource}
            columns={columns}
            onRow={(record: ServiceGateway) => {
              
              return {
                onClick: () => history.push(`/service-gateways/${record.id}`)
              }

            }}
            pagination={{
              defaultCurrent: initialPage,
              defaultPageSize: initialPageSize,
              pageSizeOptions: ['20', '50', '100', '200'],
              position: ["topCenter", "bottomCenter"],
              onChange: (page: number, pageSize: number | undefined) => {

                localStorage.setItem('LSP-admin-sgs-list-pagination', JSON.stringify({page, pageSize}));
    
                if (page > 1 && pageSize) {
    
                  setPageIndex(pageSize * (page - 1));
            
                } else {
    
                  setPageIndex(0);
    
                }
    
                return;
    
              }
            }}
            scroll={{
              x: tableWidth,
            }}
          />
        </Col>
      </Row>
    </Spin>
  )

}
