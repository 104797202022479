import { service } from 'api';
import { UpdateScaleTypeParams, AddScaleTypeParams } from 'types';


export const getScaleTypes = () => service(`/api/v1/scale-types/admin-all`, {method: 'GET'});

export const updateScaleType = (params: UpdateScaleTypeParams) => service(`/api/v1/scale-types/admin-edit`, {method: 'PUT', data: params});

export const addNewScaleType = (params: AddScaleTypeParams) => service('/api/v1/scale-types/admin-add', {method: 'POST', data: params});


