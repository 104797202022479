
import React, { ReactNode } from 'react';
import MainNav from 'components/MainNav';
import { Layout, Avatar, Popover } from 'antd';
import logo from 'assets/images/logo.png';
import { Router } from 'react-router-dom';

const { Content, Footer, Sider, Header } = Layout;

type DefaultLayoutParams = {
  storedSession: null | { username: string },
  children: ReactNode,
}

export default function DefaultLayout({storedSession, children}: DefaultLayoutParams) {

  const username = storedSession?.username;

  const popoverContent = (
    <div>
      <span>{username}</span>
    </div>
  );

  return (
    <>
      <Header
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: 25,
          paddingLeft: 25,
        }}
      >
        <img
          src={logo}
          style={{
            height: '50%',
          }}
        />
        <Popover 
          content={popoverContent}
          placement="rightBottom"
        >
          <Avatar 
            style={{ backgroundColor: '#333', verticalAlign: 'middle' }}
            size="large"
          >
            {username ? username.substring(0, 1).toUpperCase() : null}
          </Avatar>

        </Popover>
      </Header>
      <Layout>
        <Sider
          theme="light"
          breakpoint="md"
          // collapsed
          collapsible
          onBreakpoint={broken => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}

        >
          <MainNav />
        </Sider>
        <Content 
          style={{
            // margin: '24px 16px 0',
            minHeight: '100vh',
            // width: '100%',
          }}
        >
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
      {/* <Footer style={{ textAlign: 'center' }}></Footer> */}
    </>
  );

}