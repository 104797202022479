import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { AddNewProductionPlaceParams, EditProductionPlaceParams } from 'types';
const { 
  getAllProductionPlaces, 
  getProductionPlace,
  updateProductionPlace,
  disableProductionPlace,
  reEnableProductionPlace,
  addNewProductionPlace,
} = api;

export function useGetProdplaces() {

  return useQuery(
    ['get-production-places'], 
    getAllProductionPlaces,
  );

}

export function useGetProductionPlace(productionPlaceId: number) {

  return useQuery(
    ['get-production-place', productionPlaceId], 
    getProductionPlace,
    {
      enabled: !!productionPlaceId,
    }
  );

}

export const useUpdateProductionPlace = () => useMutation((params: EditProductionPlaceParams) => updateProductionPlace(params));

export const useDisableProductionPlace = () => useMutation((id: number) => disableProductionPlace(id));

export const useReEnableProductionPlace = () => useMutation((id: number) => reEnableProductionPlace(id));

export const useAddNewProductionPlace = () => useMutation((params: AddNewProductionPlaceParams) => addNewProductionPlace(params));
