
type Country = {
  label: string,
  value: number,
}

export default function (countries: Array<Country>) { 

  return [
    {
      name: "email",
      label: "Email",
      type: "input",
      props: {
        required: true,
      }
    },
    // {
    //   name: "alternateEmail",
    //   label: "Alternate email",
    //   type: "input",
    // },
    {
      name: "firstName",
      label: "First Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "phone",
      label: "Phone",
      type: "input",
    },
    {
      name: "countryId",
      label: "Country",
      type: "select",
      options: countries,
    },
    {
      name: "companyName",
      label: "Company name",
      type: "input",
    },
    {
      name: "address1",
      label: "Address",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "address2",
      label: "Address (extra)",
      type: "input",
    },
    {
      name: "city",
      label: "City",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "district",
      label: "District",
      type: "input",
    },
    {
      name: "zipCode",
      label: "Zip code",
      type: "input",
    },
    {
      name: "billCountryId",
      label: "Country billing",
      type: "select",
      options: countries,
    },
    {
      name: "billingAddress1",
      label: "Address billing",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "billingAddress2",
      label: "Address (extra) billing",
      type: "input",
    },
    {
      name: "billingCity",
      label: "City billing",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "billingDistrict",
      label: "District billing",
      type: "input",
    },
    {
      name: "billingZipCode",
      label: "Zip Code billing",
      type: "input",
    },
  ];

}