import { service } from 'api';
import { AnimalWeightSpanParams } from 'types';

export const getWeightsInDateSpan = (params: AnimalWeightSpanParams) => service('/api/v1/weights/admin-get-weights-for-date', {method: 'GET', params});

export const getAnimalInfo = (params: {id: string}) => service('/api/v1/animals/additional-information', {method: 'GET', params});

export const getTotalAnimalsByProductionPlace = ({queryKey}: {queryKey: [string, string]}) => {

  const [_key, prodPlaceId] = queryKey;

  return service(`/api/v1/animals/get-total-ppn-animals`, {method: 'GET', params: {prodPlaceId}})
  
};
