
import { Table } from 'antd';
import styled from 'styled-components';

const TableWithRowHover = styled(Table)`
  ${(props) => {
    const onRowImplementation = props.onRow && props.onRow({})
    if (onRowImplementation?.onClick) {
      return `
        tbody tr:hover {
          cursor: pointer;
        }
        `
    }
  }}
`
export default TableWithRowHover;