import React from 'react';
import { Col, Row, Spin, Statistic, Typography, Card } from 'antd';
import { GiCow } from 'react-icons/gi';
import useStatistics from 'hooks/useStatistics';

const { Title } = Typography;

const StartPage = () => {

  const { isLoading, data } = useStatistics();



  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Title>Dashboard</Title>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Card>
                <Statistic 
                  title="Farms"
                  value={data?.farmsCount}
                  loading={isLoading}
                  valueStyle={{
                    fontSize: 52,
                  }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic 
                  title="Accounts"
                  value={data?.accountsCount}
                  loading={isLoading}
                  valueStyle={{
                    fontSize: 52,
                  }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic 
                  title="Production places"
                  value={data?.prodPlacesCount}
                  loading={isLoading}
                  valueStyle={{
                    fontSize: 52,
                  }}
                />
              </Card>
            </Col>
            
          </Row>
          <Title 
            level={2}
            style={{
              marginTop: 50,
            }}
          >
            Other data
          </Title>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Card>
                <Statistic 
                  title="Weight readings"
                  value={data?.weightsCount}
                  loading={isLoading}
                  valueStyle={{
                    fontSize: 52,
                  }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic 
                  title="Living animals"  
                  value={data?.livingAnimalsCount}
                  loading={isLoading}
                  valueStyle={{
                    fontSize: 52,
                  }}
                />
              </Card>
            </Col>  
          </Row>
      </Col>
    </Row>
  );
}

export default StartPage;
