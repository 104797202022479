import { Card, List, } from "antd";
import { Account } from "types";
import { RiExternalLinkLine } from 'react-icons/ri';

type GeneralInfoCardParams = {
  account: Account,
}

export default function GeneralInfoCard({
  account,
}: GeneralInfoCardParams) {

  const {
    email,
    firstName,
    lastName,
    address1,
    address2,
    city,
    countryName,
    district,
    mobilePhone,
    phone,
    id
  } = account;

  return (
    <Card title="General info" extra={<a href={`https://app.intercom.com/apps/qek7c8xo/users/show?user_id=${id}`} target="_blank">Open in intercom <RiExternalLinkLine style={{marginBottom: -2,}}/></a>} bordered={false}>
      <List
        bordered={false}
      >
        <List.Item>
          <strong>Email</strong> <br/> 
          <a href={`mailto:${email}`} target="_blank">{email}</a>
        </List.Item>
        <List.Item>
          <strong>Name</strong> <br/> 
          {firstName} {lastName}
        </List.Item>
        <List.Item>
          <strong>Address</strong> <br/>
          {address1} <br/>
          {address2} <br/>
          {city} <br/>
          {countryName} <br />
          {district} <br />
        </List.Item>
        <List.Item>
          <strong>Phone</strong>  <br/>
          {mobilePhone} | {phone}
        </List.Item>
        <List.Item>
          <strong>User ID (Hencols internal)</strong>  <br/>
          {id}
        </List.Item>
      </List>
    </Card>
  );

}