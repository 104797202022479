
import { 
  useGetServiceGateways,
  useUpdateServiceGateway,
  useDisableServiceGateway,
  useReEnableServiceGateway,
  useAddNewServiceGateway,
} from 'hooks/useServiceGateways';

export default function useEquipmentPage() {

  // Service gateways
  const { isLoading: isFetchingServiceGateways, data: serviceGateways = {}, isSuccess: hasFetchedServiceGateways, refetch: refetchServiceGateways } = useGetServiceGateways();
  const { isLoading: isUpdatingServiceGateway, isSuccess: hasUpdatedServiceGateway, mutate: updateServiceGateway } = useUpdateServiceGateway();
  const { isLoading: isDisablingServiceGateway, isSuccess: hasDisabledServiceGateway, mutate: disableServiceGateway } = useDisableServiceGateway();
  const { isLoading: isReEnableServiceGateway, isSuccess: hasReEnabledServiceGateway, mutate: reEnableServiceGateway } = useReEnableServiceGateway();
  const { isLoading: isAddingNewServiceGateway, isSuccess: hasAddedNewServiceGateway, mutate: addNewServiceGateway } = useAddNewServiceGateway();

  const serviceGatewayActions =  {
    isFetchingServiceGateways,
    serviceGateways,
    hasFetchedServiceGateways,
    refetchServiceGateways,
    isUpdatingServiceGateway,
    hasUpdatedServiceGateway,
    updateServiceGateway,
    isDisablingServiceGateway,
    hasDisabledServiceGateway,
    disableServiceGateway,
    isReEnableServiceGateway,
    hasReEnabledServiceGateway,
    reEnableServiceGateway,
    isAddingNewServiceGateway,
    hasAddedNewServiceGateway,
    addNewServiceGateway,
  };

  return {
    ...serviceGatewayActions,
  }

}