

const dev = {
  LSP: {
    url: 'https://develop.hencol.tech:8090',
  },
  googleApiKey: "AIzaSyAt1UQI2F-S8T97FoAoM4Ry3yy2icHa3Ow",
};

const prod = {
  LSP: {
    url: 'https://lsp.hencol.com:8090',
  },
  googleApiKey: "AIzaSyAt1UQI2F-S8T97FoAoM4Ry3yy2icHa3Ow",
};

const config = process.env?.REACT_APP_ENVIRONMENT === "production"
  ? prod
  : dev;

export default {
  ...config
};
