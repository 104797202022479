import { useQuery, useMutation } from 'react-query';
import api from 'api';
const { getAllGroups, getAccountsByGroup, addGroup } = api;

export function useGetAllGroups() {

  return useQuery('all-groups', getAllGroups);

}

export function useGetAccountsByGroup(groupId: number | null) {

  return useQuery(
    ['get-accounts-by-group', groupId], 
    getAccountsByGroup,
    {
      enabled: !!groupId,
    }
  );

}

export const useAddNewGroup = () => useMutation((params: {accountIds: Array<string>, groupName: string}) => addGroup(params));