import { service } from 'api';
import { } from 'types';

export const getAllGroups = () => service(`/api/v1/groups/admin-all`, {method: 'GET'});

export const editGroup = (params: {groupId: number, groupName: string}) => service(`/api/v1/groups/admin-edit`, {method: 'POST', data: params});

export const addGroup = (params: {accountIds: Array<string>, groupName: string}) => service('/api/v1/groups/admin-add', {method: 'POST', data: params});

export const getAccountsByGroup = ({queryKey}: {queryKey: [string, string]}) => {

  const [_key, groupId] = queryKey;

  const params = {
    id: groupId,
  }

  return service(`/api/v1/groups/admin-get-accounts-by-group`, {method: 'GET', params});
  
};



