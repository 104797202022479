import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Input, Row, Spin, Table, Typography, Button, List } from 'antd';
import { colors, highlightText } from 'utils';
import { map, filter } from 'lodash';
import { AccountItem, Group } from 'types';
import { useHistory } from "react-router-dom";
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import { Link } from 'react-router-dom';
import { RiExternalLinkLine } from 'react-icons/ri';
// import HandleGroupForm from 'components/HandleGroupForm';

const { Title } = Typography;

type AllGroupsParams = {
  groups: Array<Group>
  isFetchingGroups: boolean,
  hasFetchedGroups: boolean,
  refetchGroups: () => void,
  accountsByGroups: any,
  isFetchingAccountsByGroups: boolean,
  hasFetchedAccountsByGroups: boolean,
  setGroupIdForAccounts: Dispatch<SetStateAction<null | number>>,
}

export default function AllGroups ({
  groups,
  isFetchingGroups,
  hasFetchedGroups,
  refetchGroups,
  accountsByGroups,
  isFetchingAccountsByGroups,
  hasFetchedAccountsByGroups,
  setGroupIdForAccounts,
}: AllGroupsParams) {

  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {

    // if (hasFetchedAccountsByGroups) {

    //   setGroupIdForAccounts(null);

    // }

  }, [hasFetchedAccountsByGroups])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const text = event.target.value;
    const searchText = text.length >= 1 ? text : '';
    
    setSearchText(searchText);

  }

  const dataSource = map(groups, (group: Group) => {

    return {
      ...group,
      key: group.id,
    };

  });

  const columns = [
    {
      title: `Name`,
      dataIndex: 'name',
      sorter:(a: Group, b: Group) => ((a.name.length && b.name.length) && (a.name !== '-' && b.name !== '-')) ? a.name?.localeCompare(b?.name) : false,
      render: (text: string) => highlightText(text, searchText),
    },
    {
      title: 'Users in group',
      dataIndex: 'accounts',
      render: (text: Array<string>) => text.length,
    },
    // {
    //   title: 'Actions',
    //   // dataIndex: 'accounts',
    //   key: 'actions',
    //   render: (text: Array<string>, record: Group) => {

    //     return (
    //       <>
    //         <Button type="primary">
    //           Edit
    //         </Button>
    //       </>
    //     )

    //   },  
    // }
  ];

  const filteredAccounts = (accounts: Array<AccountItem>) => {

    return filter(accounts, (account: any) => {

      const search = searchText.toLowerCase();
      
      return account.email.toLowerCase().indexOf(search) > -1;

    });

  }

  const renderListItem = (account: AccountItem, i: number) => {

    return (
      
      <List.Item
        key={i}
      >
        <Link 
          // @ts-ignore
          to={`/accounts/${account.id}`}
        >
          {highlightText(account.email, searchText)}
        </Link>
      </List.Item>
    );

  }

  const renderListOfUsers = (group: Group) => {

    if (isFetchingAccountsByGroups) {

      return <Spin spinning={isFetchingAccountsByGroups} />;

    }

    return (
      <Row>
        <Col xs={24}>
          <Typography.Title level={3}>Users assigned to {group.name}</Typography.Title>
          <Typography.Text 
            style={{
              display: 'block',
              // fontSize: 16,
              marginBottom: 5,
            }}
          >
            Find any user
          </Typography.Text>
        </Col>
        <Col xs={24}>
          <Input
            onChange={onChange}
            placeholder={'name@domain.com'}
            style={{
              width: 300,
            }}
          />
          <Title 
            level={5} 
            style={{
              marginTop: 20,
            }}
          >Showing: {filteredAccounts(accountsByGroups).length}/{group.accounts.length}</Title>
        </Col>
        <Col xs={24}>
          <List
            style={{
              // backgroundColor: 'rgba(0, 0, 0, 0.01)',
              padding: '0 20px',
              marginTop: 20,
              border: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            
            {
              // @ts-ignore
              map(filteredAccounts(accountsByGroups), (account, i) => renderListItem(account, i))
            }
          </List>
        </Col>
      </Row>
    )
    
  }

  const handleExpand = (record: Group) => {

    setGroupIdForAccounts(record.id);

  } 

  return (
    <Spin spinning={isFetchingGroups}>
      <Col span={24}>
        <Table
          dataSource={dataSource}
          // @ts-ignore
          columns={columns}
          expandable={{
            expandedRowRender: record => renderListOfUsers(record),
            onExpand: (expanded, record) => {

              if (expanded) {
                
                // @ts-ignore
                setExpandedKeys([record.key])
                
              } else {
                
                setExpandedKeys([]);

              }

              setSearchText('');
              handleExpand(record);

            },
          }}
          expandedRowKeys={expandedKeys}
        >
        </Table>
      </Col>
    </Spin>
  );

}