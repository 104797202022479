
import { Layout, Tabs, Typography } from 'antd';
import useServiceGatewaysPage from './useServiceGatewaysPage';
import ServiceGatewayTable from './components/ServiceGatewayTable';

export default function ServiceGatewaysPage() {

  const {
    isFetchingServiceGateways,
    serviceGateways,
    hasFetchedServiceGateways,
    refetchServiceGateways,
    isUpdatingServiceGateway,
    hasUpdatedServiceGateway,
    updateServiceGateway,
    isDisablingServiceGateway,
    hasDisabledServiceGateway,
    disableServiceGateway,
    isReEnableServiceGateway,
    hasReEnabledServiceGateway,
    reEnableServiceGateway,
    isAddingNewServiceGateway,
    hasAddedNewServiceGateway,
    addNewServiceGateway,
  } = useServiceGatewaysPage();

  return (
    <Layout>
      <Typography.Title>
        Service gateways
      </Typography.Title>
      <ServiceGatewayTable 
        serviceGateways={serviceGateways}
        isFetchingServiceGateways={isFetchingServiceGateways}
        isUpdatingServiceGateway={isUpdatingServiceGateway}
        hasUpdatedServiceGateway={hasUpdatedServiceGateway}
        updateServiceGateway={updateServiceGateway}
        isDisablingServiceGateway={isDisablingServiceGateway}
        hasDisabledServiceGateway={hasDisabledServiceGateway}
        disableServiceGateway={disableServiceGateway}
        isReEnableServiceGateway={isReEnableServiceGateway}
        hasReEnabledServiceGateway={hasReEnabledServiceGateway}
        reEnableServiceGateway={reEnableServiceGateway}
        refetchServiceGateways={refetchServiceGateways}
        isAddingNewServiceGateway={isAddingNewServiceGateway}
        hasAddedNewServiceGateway={hasAddedNewServiceGateway}
        addNewServiceGateway={addNewServiceGateway}
      />
    </Layout>
  );

}