import { Tabs } from 'antd';
import FarmMap from './components/farmMap';
import useSalesPage from './useSalesPage';

export default function SalesPage () {

  const {
    isFetchingAllCompanies,
    hasFetchedAllCompanies,
    allCompanies,
    fetchAllCompanies,
  } = useSalesPage();

  return (
    <Tabs defaultActiveKey="map">
      <Tabs.TabPane tab="Upsales Map" key="Map">
        <FarmMap 
          isFetchingAllCompanies={isFetchingAllCompanies}
          hasFetchedAllCompanies={hasFetchedAllCompanies}
          allCompanies={allCompanies}
          // @ts-ignore
          fetchAllCompanies={fetchAllCompanies}
        />
      </Tabs.TabPane>
      {/* <Tabs.TabPane tab="Tab 2" key="2">
        Content of Tab Pane 2
      </Tabs.TabPane>
      <Tabs.TabPane tab="Tab 3" key="3">
        Content of Tab Pane 3
      </Tabs.TabPane> */}
    </Tabs>
  )

}