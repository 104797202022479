import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { LoginDetails } from 'types';
const { refreshLogin, login } = api;

export function useRefreshLogin() {

  return useQuery('refresh-login', refreshLogin);

}

export function useLogin() {

  return useMutation((params: LoginDetails) => login(params));

}
