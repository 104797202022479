import { useGetScales, useUpdateScale,useDisableScale, useReEnableScale, useAddNewScale } from "hooks/useScales";
import { useGetScaleTypes } from 'hooks/useScaleTypes';
import { useGetServiceGateways } from 'hooks/useServiceGateways';

export default function useScalesPage() {

  const { isLoading: isFetchingScales, data: scales = {}, isSuccess: hasFetchedScales, refetch: refetchScales } = useGetScales();
  const { isLoading: isFetchingScaleTypes, data: scaleTypes = {}, isSuccess: hasFetchedScaleTypes } = useGetScaleTypes();
  const { isLoading: isUpdatingScale, isSuccess: hasUpdatedScale, mutate: updateScale } = useUpdateScale();
  const { isLoading: isDisablingScale, isSuccess: hasDisabledScale, mutate: disableScale } = useDisableScale();
  const { isLoading: isReEnableScale, isSuccess: hasReEnabledScale, mutate: reEnableScale } = useReEnableScale();
  const { isLoading: isAddingNewScale, isSuccess: hasAddedNewScale, mutate: addNewScale, } = useAddNewScale();
  const { isLoading: isFetchingServiceGateways, data: serviceGateways = {}, isSuccess: hasFetchedServiceGateways, refetch: refetchServiceGateways } = useGetServiceGateways();

  return {
    isFetchingScales,
    scales,
    refetchScales,
    hasFetchedScales,
    useGetScaleTypes,
    isFetchingScaleTypes,
    scaleTypes,
    hasFetchedScaleTypes,
    isUpdatingScale,
    hasUpdatedScale,
    updateScale,
    isDisablingScale,
    hasDisabledScale,
    disableScale,
    isReEnableScale,
    hasReEnabledScale,
    reEnableScale,
    isAddingNewScale,
    hasAddedNewScale,
    addNewScale,
    isFetchingServiceGateways,
    serviceGateways,
    hasFetchedServiceGateways,
    refetchServiceGateways,
  }

}