
import { useEffect, useState } from 'react';
import useAccountsPage from './useAccounts';
import { Table, Spin, Row, Col, Button, Input, Typography, Tag, Modal, Statistic } from 'antd';
import { AccountListItem } from 'types';
import { Link } from 'react-router-dom';
import ForwardLink from 'components/ForwardLink';
import { map, filter } from 'lodash';
import SimpleForm from 'components/SimpleForm';
import accountFields from './accountFields';
import AccountsTable from './components/AccountsTable';
import { AccountFormItem } from 'types';

export default function AccountsPage () {

  const {
    isFetchingAccounts,
    accounts,
    hasFetchedAccounts,
    refetchAccounts,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isAddingNewAccount,
    hasAddedNewAccount,
    addNewAccount,
    newAccountData,
  } = useAccountsPage();

  const [searchText, setSearchText] = useState('');
  const [addNew, setAddNew] = useState(false);
  const [newUserPassword, setNewUserPassword] = useState(null);
  const [newUsername, setNewUsername] = useState(null);
  const [newUserModalIsVisible, setNewUserModalIsVisible] = useState(false);

  useEffect(() => {

    if (hasAddedNewAccount) {
    
      setNewUserPassword(newAccountData.metadata[0]);
      setNewUserModalIsVisible(true);
    
    }
    
  }, [hasAddedNewAccount])


  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const text = event.target.value;
    const searchText = text.length >= 1 ? text : '';
    
    setSearchText(searchText);

  }

  const onFinishAdd = ({
    email,
    alternateEmail,
    firstName,
    lastName,
    phone,
    countryId,
    companyName,
    address1,
    address2,
    city,
    district,
    zipCode,
    billCountryId,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingDistrict,
    billingZipCode,
  }: AccountFormItem) => {
    
    const formatedData = {
      addressData: {
        address1,
        address2,
        city,
        district,
        zipCode
      },
      alternateEmail,
      billAddressData: {
        address1: billingAddress1,
        address2: billingAddress2,
        city: billingCity,
        district: billingDistrict,
        zipCode: billingZipCode,
      },
      billCountryId,
      companyName,
      countryId,
      email,
      firstName,
      lastName,
      login: email,
    }

    addNewAccount(formatedData);

    setNewUsername(email);
    
  }

  const onCloseModal = () => {
            
    setNewUserModalIsVisible(false);
    setAddNew(false);
    setNewUserPassword(null);
    setNewUsername(null);
    refetchAccounts();
  
  }

  if (addNew) {

    const formatedCountries = map(countries, ({country, id}) => {
      return {
        label: country,
        value: id,
      }
    })

    return (
      <>
        <SimpleForm 
          fields={accountFields(formatedCountries)}
          onFinish={(values: any) => onFinishAdd(values)}
          backAction={() => setAddNew(false)}
          header="Add new user account"
        />
         <Modal 
          title="New user created" 
          visible={newUserModalIsVisible} 
          closable={false}
          onOk={onCloseModal} 
          onCancel={onCloseModal}
        >
          <Row>
            <Col
              xs={12}
            >
              <Statistic
                title="Username"
                value={newUsername} 
              />
            </Col>
            <Col
              xs={12}
            >
              <Statistic 
                title="Password" 
                value={newUserPassword} 
              />
            </Col>
          </Row>
        </Modal>
      </>
    );

  }

  const accountsWithKey = map(accounts, (account) => {
    
    return {
      key: account.id,
      ...account,
    }
  
  });

  const dataSource: Array<AccountListItem> = filter(accountsWithKey, (account: AccountListItem) => {
  
    const { firstName, lastName, email } = account;
  
    const search = searchText.toLowerCase();
    
    return firstName?.toLowerCase().indexOf(search) > -1 || lastName?.toLowerCase().indexOf(search) > -1 || email?.toLowerCase().indexOf(search) > -1;
  
  });

  return (
    <Spin spinning={isFetchingAccounts}>
      <Typography.Title>
        Users
      </Typography.Title>
      <Link
        to="/accounts/groups"
        style={{
          display: 'block',
          marginTop: -10,
          marginBottom: 10,
        }}
      >
        <ForwardLink text="User groups" />
      </Link>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            onClick={() => {

              setAddNew(true);

            }}
          >
            Add new
          </Button>
        </Col>
        <Col
          span={12}
          style={{
            maxWidth: 400
          }}
        >
          <strong>Filter</strong>
          <Input
            onChange={onChange}
            placeholder={'Filter by first name, last name or email'}
          />
          <Typography.Title 
            level={5} 
            style={{
              marginTop: 20,
            }}
          >Showing: {dataSource.length}/{accountsWithKey.length}</Typography.Title>
        </Col>
        <Col span={24}>
          <AccountsTable 
            dataSource={dataSource}
            searchText={searchText}
          />
        </Col>
      </Row>
    </Spin>
  );

}