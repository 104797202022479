import { useState } from 'react';
import useProductionPlacesPage from './useProductionPlaces';
import AllProductionPlaces from './components/AllProductionPlaces';
import { Layout, Typography } from 'antd';


export default function ProductionPlacesPage() {

  const {
    hasFetchedProductionPlaces,
    productionPlaces,
    isFetchingProductionPlaces,
    refetchProductionPlaces,
  } = useProductionPlacesPage();

  const [searchText, setSearchText] = useState('');

  return (
    <Layout>  
      <Typography.Title>
        Production Places
      </Typography.Title>
      <AllProductionPlaces 
        productionPlaces={productionPlaces}
        isFetchingProductionPlaces={isFetchingProductionPlaces}
        hasFetchedProductionPlaces={hasFetchedProductionPlaces}
        setSearchText={setSearchText}
        searchText={searchText}
        refetchProductionPlaces={refetchProductionPlaces}
      />
    </Layout>
  );

}