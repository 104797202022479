

import { IoChevronBackSharp } from 'react-icons/io5';

export default function BackLink({text}: {text: string}) {

  return (
    <>
      <IoChevronBackSharp 
        style={{
          marginBottom: -3,
          height: '16px',
          width: '16px',
        }}
      /> {text}
    </>
  );

}