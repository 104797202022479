
import { Card, Typography, Input, Form, Select, Button, Checkbox, Switch } from 'antd';
import { map } from 'lodash';
import BackLink from 'components/BackLink';
import TextArea from 'antd/lib/input/TextArea';

export type AddNewFormParams = {
  fields: Array<any>,
  backAction: () => void,
  onFinish: (args0: any) => void,
  header?: string,
  initialValues?: Array<object>,
}

export default function AddNewForm ({
  fields,
  backAction,
  onFinish,
  header,
  initialValues
}: AddNewFormParams) {

  return (
    <Card>
      <a
        href="#"
        style={{
          display: 'block',
          marginBottom: 10,
        }}
        onClick={(e) => {

          e.preventDefault();
          backAction();

        }}
      >
        <BackLink text="Back"/>
      </a>
      <Typography.Title level={2}>
        {header || 'Add new'}
      </Typography.Title>
      <Form
        initialValues={initialValues || null}
        layout="vertical"
        onFinish={onFinish}
        style={{
          maxWidth: 400,
        }}
      > 
        {map(fields, (field) => {

          let element = null;

          switch (field.type) {
            case 'input':
              element = <Input {...field.props}/>
              break;
            
            case 'textarea':
              element = <TextArea {...field.props}/>
              break;
            
            case 'switch':
              element = <Switch
                {...field.props}
                defaultChecked={initialValues?.[field.name]}
              />
              break;

            case 'select':
              element = <Select 
                {...field.props}
                showSearch
                options={field.options}
                filterOption={(input, option) => {
                  //@ts-ignore
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                filterSort={(optionA, optionB) =>
                  //@ts-ignore
                  optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                }
              />
              break;
          
            default:
              break;
          }

          return (
            <Form.Item
              key={field.name}
              name={field.name}
              label={field.label}
              {...field.props}
            >
              {element}
            </Form.Item>
          );

        })}
        <Form.Item>
          <Button 
            type="primary"
            htmlType="submit"
            size="large"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

}