
import { Table, Layout, Row, Col, Card, Typography } from 'antd';
import projects from './projects';
import { map } from 'lodash';

export default function ProjectsPage () {

  const dataSource = map(projects, (project, i) => {

    return {
      key: i,
      ...project,
    }

  });

  const columns = [
    {
      title: 'Row',
      dataIndex: 'key',
      render: (text: number) => text + 1,
    },
    {
      title: "Name",
      key: "name",
      width: 300,
      render: (record: any) => {

        return (
          <>
            <span
              style={{
                display: 'block',
                fontWeight: 'bold',
              }}
            >
              {record.name} - 
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: 12,
                  color: (record.status === 'Started' || record.status === 'Closing') ? 'green' : '#333',
                  marginLeft: 5,
                }}
              >
                {record.status}
              </span>
            </span>
            <span
              style={{
                display: 'block',
              }}
            >
              {record.goal}
            </span>
          </>
        )

      }
    },
    {
      title: "Priority",
      dataIndex: "priority",
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
    },
    {
      title: "Owner Internal",
      dataIndex: "owner",
      render: (text: string | null, record: any) => record?.owner?.internal,
    },
    {
      title: "Owner External",
      dataIndex: "owner",
      render: (text: string | null, record: any) => record?.owner?.external,
    },
    {
      title: "Financier",
      dataIndex: "financier",
    }
  ]

  return (
    <Layout>
      <Row>
        <Col span={24}>
          <Typography.Title level={1}>
            Projects
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Card>
            <Table 
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </Layout>
  );

}