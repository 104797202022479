import React, { ReactChild, useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineDashboard } from 'react-icons/ai';
import { GiBarn, GiCow } from 'react-icons/gi';
import { FaWarehouse, FaMapMarker } from 'react-icons/fa';
import { CgPlayListAdd } from 'react-icons/cg';
import { RiAccountCircleLine, RiScales2Fill } from 'react-icons/ri';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { BsCardChecklist } from 'react-icons/bs';
import { filter, map } from 'lodash';
import { useEffect } from 'react';

const menuItemStyles = {
  border: 'none',
  // display: 'flex',
  // justifyContent: 'flex-start',
  // alignItems: 'center',
}
const iconStyle = (adjustment = 0, extraLeftMargin = 0) => {

  return {
    fontSize: adjustment ? 20 + adjustment : 20,
    marginBottom: -5,
    // padding: '0px 3px',
    marginRight: 10 + (extraLeftMargin/2),
    marginLeft: extraLeftMargin,
  }

}

const menuItems = [
  {
    icon: <AiOutlineDashboard style={iconStyle()} />,
    url: '/',
    text: 'Dashboard',
  },
  // {
  //   icon: <BsCardChecklist style={iconStyle()} />,
  //   url: '/projects',
  //   text: 'Projects',
  // },
  {
    icon: <RiAccountCircleLine style={iconStyle()} />,
    url: '/accounts',
    text: 'Users',
  },
  {
    icon: <GiCow style={iconStyle(4, -2)} />,
    url: '/animals',
    text: 'Animals',
  },
  {
    icon: <GiBarn style={iconStyle(2)} />,
    url: '/farms',
    text: 'Farms',
  },
  {
    icon: <FaWarehouse style={iconStyle(-2, 3)} />,
    url: '/production-places',
    text: 'Production places',
  },
  {
    icon: <AiOutlineCloudServer style={iconStyle(4)} />,
    url: '/service-gateways',
    text: 'Service gateways',
  },
  {
    icon: <RiScales2Fill style={iconStyle(2)} />,
    url: '/scales',
    text: 'Scales',
  },
  {
    icon: <FaMapMarker style={iconStyle(2)} />,
    url: '/sales',
    text: 'Sales',
  },
  // {
  //   icon: <CgPlayListAdd style={iconStyle(2)} />,
  //   url: '/species',
  //   text: 'Species & breeds',
  // },
]

const MainNav = () => {

  const location = useLocation();
  const [current, setCurrent] = useState<string[]>([menuItems[0].url]);

  const handleClick = (e: any) => {

    setCurrent(e.key);

  }

  const updateCurrent = () => {

    const currentOption: any = filter(menuItems, (menuItem: { icon: ReactChild, url: string, text: string}) => {

      return menuItem.url.length > 1 && location.pathname.indexOf(menuItem.url) > -1;

    });

    const value = currentOption.length ? currentOption[0].url : menuItems[0].url;

    setCurrent(value);

  }

  useEffect(() => {

    updateCurrent();

  }, []);

  useEffect(() => {

    updateCurrent();

  }, [location])

  
  return (  
    <Menu
      theme="light"
      mode="inline"
      style={{
        border: 'none',
      }}
      //@ts-ignore
      selectedKeys={[current]}
      onClick={handleClick}
    >

      {map(menuItems, ({icon, url, text}, i) => (
        <Menu.Item
          key={url}
          icon={icon}
          style={menuItemStyles}
        >
          <Link 
            to={url}
          >
            {text}
          </Link>
        </Menu.Item>
      ))}
      
    </Menu>
  );
}

export default MainNav;