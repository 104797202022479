  import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import api from 'api';
import { Col, Row, Spin, Table, Typography, Button, message } from 'antd';
import { useBreedsBySpecies } from 'hooks/useBreeds';
import { Breed, AddBreedDetails } from 'types';
import AddBreedModal from 'components/AddBreedModal';
import { map, sortBy } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const { addBreed } = api;

const BreedsTable = ({speciesId, speciesName}: {speciesId: number, speciesName: string}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, data: breeds } = useBreedsBySpecies(speciesId);
  
  const addBreedsMutation = useMutation(
    (params: AddBreedDetails) => addBreed(params), {
      onSuccess: (data, variables, context) => {
        
        setIsModalVisible(false);
        message.success(`New breed successfully added`);

      }
    }
  )

  console.log(breeds);

  if (!breeds) {

    return (
      <div></div>
    );

  }

  const dataSource = map(breeds, (item: Breed) => {

    return {
      ...item,
      key: item.id,
    }

  })

  const handleOk = (formValues: AddBreedDetails) => {

    addBreedsMutation.mutate(formValues);

  };

  const columns = [
    { 
      title: 'Code', 
      dataIndex: 'code',
      key: 'code',
      
      sorter: {
        compare: (a: Breed, b: Breed) => {
          
          if (a.code < b.code) {
            return -1;
          }
          if (a.code > b.code) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
    },
    { 
      title: 'Name', 
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: Breed, b: Breed) => {
          
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]}>
        <Col 
          span={24} 
        >
          <Title 
            level={5}
          >
            Breeds on species {speciesName} ({breeds.length}st)
          </Title>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Add new breed
          </Button>
          <AddBreedModal
            isVisible={isModalVisible}
            handleOk={handleOk} 
            handleCancel={() => setIsModalVisible(false)}
            confirmLoading={addBreedsMutation.isLoading}
            speciesId={speciesId}
            speciesName={speciesName}
          />
        </Col>
        <Col span={24}>
          <Table 
            columns={columns}
            dataSource={sortBy(dataSource, 'code')}
            pagination={false}
          />
        </Col>
      </Row>
    </Spin>
  );

}

export default BreedsTable;