
import { useFetchAllCompanies } from 'hooks/useUpsales';

export default function useSalesPage () {

  const { isLoading: isFetchingAllCompanies, isSuccess: hasFetchedAllCompanies, data: allCompanies = {}, mutate: fetchAllCompanies} = useFetchAllCompanies();
  
  return {
    isFetchingAllCompanies,
    hasFetchedAllCompanies,
    allCompanies,
    fetchAllCompanies,
  }

}