import { List } from 'antd';
import { map } from 'lodash';
import { ReactChild } from 'react';
import { colors } from 'utils';

export default function ListItemWithTitle ({title, items}: {title: string, items: Array<string | ReactChild>}) {

  return (
    <List.Item
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <strong>{title}</strong>
      {map(items, (item, i) => {

        let content = item ? item : '-';

        return (
          <div>
            {content}
          </div>
        )

      })}
    </List.Item>
  )

}