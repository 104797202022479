

import { IoChevronForwardSharp } from 'react-icons/io5';

export default function ForwardLink({text}: {text: string}) {

  return (
    <>
      {text}
      <IoChevronForwardSharp 
        style={{
          marginBottom: '-4px',
          height: '16px',
          width: '16px',
        }}
      />
    </>
  );

}