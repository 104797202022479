import React from 'react';
import './App.css';
import routes from './routes';
import Login from './pages/login';
import { BrowserRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import DefaultLayout from 'components/DefaultLayout';

function App() {

  const { token } = useSelector((state: any) => state.login);
  const storedSession: JSON | string | null = localStorage.getItem('hencol-admin-session');
  const loggedIn = storedSession || token  ? true : false;
  
  if (!loggedIn) {

    return (
      <Login></Login>
    )

  }

  return (
    <BrowserRouter  basename="/#">
      <DefaultLayout
        storedSession={storedSession ? JSON.parse(storedSession) : null}
      >
        {routes}
      </DefaultLayout>
    </BrowserRouter>
  );
}

export default App;
